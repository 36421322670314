<template>
  <div>
    <hooper  class="hooperContainer"
      style="height: 100%;padding-top: 0px;padding-bottom: 0px;"
      :rtl=" $i18n.locale == 'ar' ? true : false"
      :settings="hooperSettings"
    >
      <slide style="padding:10px" v-for="(item,index) in items" :key="index">
        <card1 :name="item.name" :image="item.icon" :sectionid="item.id" :active="item.active"></card1>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
      <!--<hooper-pagination slot="hooper-addons"></hooper-pagination>
      <hooper-progress slot="hooper-addons"></hooper-progress>-->
    </hooper>

    <div v-if="dispalyTab" data-aos="fade-down" data-aos-duration="3000"   style="min-height: 220px;">
      <!--<div style="
          height: 50px;
          width: 50px;
          background: var(--primary-color);
          border-radius: 50px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0;
      ">
      </div>-->
      <div class="sectionTab">
        <card-section :s_id="sectionid" :s_name="sectionName"></card-section>
      </div>
    </div>
  </div>
</template>

<script>
import cardSection from "./SectionComponent.vue";

import card1 from "./CardStyle1.vue";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "App",
  props: { items: Array },
  components: {
    cardSection,
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
    card1
  },
  data() {
    return { 
      dispalyTab: false,
      sectionid: 0,
      sectionName: "",
      hooperSettings: {
        itemsToShow: 1.5,
        initialSlide: 0,
        itemsToSlide: 2,
        /*infiniteScroll:true,*/
        autoPlay: true,
        playSpeed: 3000,
        trimWhiteSpace: true,

        //mouseDrag:false,
        //touchDrag:false,
        wheelControl:false,

        //playSpeed:2000,
        //transition:300,
        // centerMode: true,

        breakpoints: {
          290: {
            itemsToShow: 2,
            itemsToSlide: 1
          },
          375: {
            itemsToShow: 2.5,
            itemsToSlide: 1
          },
          430: {
            itemsToShow: 3,
            itemsToSlide: 1
          },
          768: {
           // itemsToShow: 5
           itemsToShow: 3,
          },
          992: {
          //  itemsToShow: 6
          itemsToShow: 3,
          }
        }
      }
    };
  },
  created() {
    for (let i = 0; i < this.items.length; i++) {
      Object.assign(this.items[i], { active: false });

      //console.log(this.items[i]);
    }
   // console.log("slider" + this.items.length);
  },
  methods: {
    dispalyTabFunction(sectionid) {
      /*  var ele = document.getElementsByClassName("sectionTab");
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.display = "none";
      }*/
      document.getElementsByClassName("hooper-prev")[0].style.marginTop = "-40px"
      document.getElementsByClassName("hooper-next")[0].style.marginTop = "-40px"

      for (let i = 0; i < this.items.length; i++) {
        this.items[i].active = false;
        if (this.items[i].id == sectionid) {
          this.items[i].active = true;
          this.sectionName= this.items[i].name;
        }
      }
      this.sectionid = sectionid;
      this.dispalyTab = true;
    }
  }
};
</script>
<style scoped>
@media (min-width: 768px) {
  .hooperContainer{
    width: 50%;
    margin:auto;
  }
}
.sectionTab {
  /*
  right: 0;
  left: 0;
  position: absolute;
  z-index: 1000;*/
  /* width: 100%;
  height: 512px;
  margin-top: -20px;
  background: #fff;
  overflow: auto;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  scrollbar-color: var(--primary-color) #e3e3e3;
  scrollbar-width: thin;*/
}
#About {
  padding-top: 250px;
  min-height: 600px;
}

h1,
h4 {
  cursor: default;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 4em;
  color: #2c3e50;
}

h4,
a {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #7f8c8d;
}

img.google {
  position: absolute;
  bottom: 0;
  left: 5px;
}

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 240px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 1) 40%
  );
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.location {
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", sans-serif;
  color: #5dc596;
}

.social {
  justify-content: flex-end;
  flex-direction: row;
}

.social iframe {
  margin: 0 4px;
}

.button {
  display: inline-block;
  width: 180px;
  padding: 12px 14px;
  background-color: #4fc08d;
  font-weight: 700;
  color: #fff;
  border-bottom: 2px solid #3aa373;
  border-radius: 4px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.button:hover {
  background-color: #5dc596;
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
}
</style>
