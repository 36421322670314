<template>
  <!-- ======= Second Section ======= -->
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <section id="Section" v-if="!loading">
      <div class="cover">
        <img :src="DBitems.section.image" />
        <!--<img src="../../public/assets/image/background2.jpg" />-->
      </div>
      <div class="mask d-flex flex-row justify-content-center align-items-center">
        <img class="image1" :src="DBitems.section.icon" />
        <!--src="../../public/assets/image/icons/electronic.png"-->
        <h2>{{ DBitems.section.name }}</h2>
      </div>
      <div class="container mt-3">
        <div class="row sectionSlider">
          <div class="col-md-10  mb-2">
            <hooper
              style="
                height: 100%;
                padding: 0 0px;
                margin: 0;
                background-color: lightgray;
                background: #fff;
              "
              :rtl="$i18n.locale == 'ar' ? true : false"
              :settings="hooperSettings"
            >
              <slide style="padding: 10px">
                <button
                  class="item"
                  @click="
                    categoryID = 0;
                    $refs.childref.update(categoryID);
                  "
                >
                  <div class="d-flex flex-row justify-content-around">
                    <h5>{{ $t("all") }}</h5>
                    <img
                      style="width: 25px; height: 25px; object-fit: cover"
                      :src="DBitems.section.icon"
                    />
                  </div>
                </button>
              </slide>
              <slide
                style="padding: 10px"
                v-for="(item, index) in DBitems.categories.category"
                :key="index"
              >
                <button
                  class="item"
                  @click="
                    categoryID = item.id;
                    $refs.childref.update(categoryID);
                  "
                >
                  <div class="d-flex flex-row justify-content-around">
                    <h5>{{ item.name }}</h5>
                    <img style="width: 25px; height: 25px; object-fit: cover" :src="item.icon" />
                  </div>
                </button>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>
          </div>
          <div class="col-md-2 col-12 p-0 mt-1 mb-4">
            <div class="d-flex flex-row justify-content-around  align-items-center">
              <i
                class="bi bi-search"
                style="font-size: 20px; padding: 0px 10%;"
                @click="search = true"
              ></i>
              <vodal
                :show="search"
                animation="flip"
                @hide="search = false"
                :width="600"
                :height="450"
                style="margin-top: 20px"
              >
                <div class="container m-4">
                  <h5 style="color: var(--primary-color)">{{ $t("filter") }} :</h5>

                  <h5 class="mt-5">{{ $t("price") }}:</h5>
                  <fieldset
                    dir="ltr"
                    style="
                      max-width: 80%;
                      margin: auto;
                      text-align: left;
                      padding: 20px;
                    "
                  >
                    <MultiRangeSlider
                      baseClassName="multi-range-slider-bar-only"
                      :minValue="minPrice"
                      :maxValue="maxPrice"
                      :max="10000"
                      :min="0"
                      :step="50"
                      :rangeMargin="0"
                      @input="update_oBarValues"
                    />
                  </fieldset>
                  <h5>{{ $t("quantity") }}:</h5>
                  <fieldset
                    dir="ltr"
                    style="
                      max-width: 80%;
                      margin: auto;
                      text-align: left;
                      padding: 20px;
                    "
                  >
                    <MultiRangeSlider
                      baseClassName="multi-range-slider-bar-only"
                      :minValue="minQuantity"
                      :maxValue="maxQuantity"
                      :max="10000"
                      :min="0"
                      :step="50"
                      :rangeMargin="0"
                      @input="update_oBarValues1"
                    />
                  </fieldset>
                  <div class="d-flex flex-row justify-content-between w-25">
                    <h5>{{ $t("offer") }}:</h5>
                    <input
                      type="checkbox"
                      id="checkbox"
                      v-model="checkBoxOffer"
                      style="width: 30px; height: 25px"
                      @change="!checkBoxOffer"
                    />
                  </div>
                  <div
                    class="button w-50 text-center"
                    @click="
                      $refs.childref.changeSearch(
                        minQuantity,
                        maxQuantity,
                        minPrice,
                        maxPrice,
                        checkBoxOffer
                      )
                    "
                  >{{ $t("go") }}</div>
                </div>
              </vodal>
              <div class="filterDropdown dropdown">
                <a>
                  <div
                    style="
                      padding: 8px 10px 12px 10px;
                      background-color: var(--primary-color);
                      border-radius: 10px;
                    "
                  >
                    <img src="../../../public/assets/image/icons/filter.svg" style="width: 25px" />
                  </div>
                </a>
                <ul>
                  <li>
                    <a @click="$refs.childref.changeSort('desc', 'id')">{{ $t("sort1") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('asc', 'id')">{{ $t("sort2") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('desc', 'sale_price')">{{ $t("sort3") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('asc', 'sale_price')">{{ $t("sort4") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('desc', 'count_view')">{{ $t("sort5") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('asc', 'count_view')">{{ $t("sort6") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('desc', 'quantity')">{{ $t("sort7") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('asc', 'quantity')">{{ $t("sort8") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('desc', 'count_sold')">{{ $t("sort9") }}</a>
                  </li>
                  <li>
                    <a @click="$refs.childref.changeSort('asc', 'count_sold')">{{ $t("sort10") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!loadingCat">
          <section-products
            ref="childref"
            :category-id="categoryID"
            :section-id="DBitems.section.id"
          ></section-products>
        </div>
      </div>
    </section>
  </div>
  <!-- End Second -->
</template>

<script>
import SectionProducts from "../components/SectionProducts.vue";
import VueHorizontalList from "vue-horizontal-list";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

import MultiRangeSlider from "multi-range-slider-vue";
import "../../../node_modules/multi-range-slider-vue/MultiRangeSliderBlack.css";
import "../../../node_modules/multi-range-slider-vue/MultiRangeSliderBarOnly.css";

import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Vodal,
    SectionProducts,
    VueHorizontalList,
    KinesisContainer,
    KinesisElement,
    MultiRangeSlider,
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation
  },
  data() {
    return {
      search: false,

      minPrice: 0,
      maxPrice: 10000,
      rangePrice: [-20, 70],

      minQuantity: 0,
      maxQuantity: 10000,
      rangeQuantity: [-20, 70],

      checkBoxOffer: false,

      options: {
        responsive: [
          { end: 576, size: 2 },
          { start: 576, end: 768, size: 4 },
          { start: 768, end: 992, size: 6 },
          { size: 7 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 5
        },
        position: {
          start: 2
        },
        autoplay: { play: false, repeat: true, speed: 2500 }
      },
      /*items: [
        { title: "headphones", icon: "bi bi-headphones" },
        { title: "laptop", icon: "bi bi-laptop" },
        { title: "printer", icon: "bi bi-printer" },
        { title: "headphones", icon: "bi bi-headphones" },
        { title: "laptop", icon: "bi bi-laptop" },
        { title: "printer", icon: "bi bi-printer" },
        { title: "headphones", icon: "bi bi-headphones" },
        { title: "laptop", icon: "bi bi-laptop" },
        { title: "printer", icon: "bi bi-printer" },
      ],*/
      DBitems: null,
      loading: false,
      loadingCat:false,
      categoryID: 0,
      //langChange:""

      hooperSettings: {
        itemsToShow: 1,
        initialSlide: 0,
        itemsToSlide: 2,
        trimWhiteSpace: true,
        /*infiniteScroll: true,
        autoPlay: true,
        playSpeed: 3000,*/
        //playSpeed:2000,
        //transition:300,

        breakpoints: {
          375: {
            itemsToShow: 2,
            itemsToSlide: 1
          },
          430: {
            itemsToShow: 2.5,
            itemsToSlide: 1
          },
          768: {
            itemsToShow: 4
          },
          992: {
            itemsToShow: 5
          }
        }
      }
    };
  },
  created() {
    this.loading = true;
    this.loadingCat = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/section/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
      })
      .then(() => {this.loading = false;})
      .then(() => {
       if (this.$route.params.category_id_selected != null) {
          let x=  this.$route.params.category_id_selected;
          //console.log(x)
          //console.log(this.$refs.childref)
          this.categoryID=x;
          this.$refs.childref.update(this.categoryID);
        } } ).then(() => {this.loadingCat = false;});
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/section/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },
    update_oBarValues(e) {
      this.minPrice = e.minValue;
      this.maxPrice = e.maxValue;
    },
    update_oBarValues1(e) {
      this.minQuantity = e.minValue;
      this.maxQuantity = e.maxValue;
    },
    updatedataRouter() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/section/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
          this.categoryID = 0;
          /// this.$refs.childref.update(this.categoryID);
          //console.log("hiii");
        })
        .then(() => (this.loading = false));
    }
  },
  watch: {
    $route: function() {
      this.updatedataRouter();
    }
  }
  /* updated() {
    this.langChange = this.$i18n.locale;
  },*/
  /*watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/section/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.section;
        })
        .then(() => (this.loading = false));
    },
  },*/
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Section {
  margin-top: 0;
  padding: 0;
}

.mask {
  /*background-image: linear-gradient(
    to left,
    rgb(0, 0, 0, 0.9),
    rgb(255, 255, 255, 0)
  );*/
  position: absolute;
  right: 0;
  top: 140px;
  left: 0;
  width: 100%;
  overflow: hidden;
  /*height: 325px;*/
  aspect-ratio: 1200 / 400;
}
.image1 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: 0 10px;
  /*margin-right: 100px;
  margin-left: 20px;*/
}
.bi-search:hover {
  transform: scale(1.03);
  color: var(--primary-color);
}

.item {
  font-size: 14px;
  background: #fff;
  border: 0;
  box-shadow: 0 2px 10px 0 #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 7px 0 2px 0;
  /* padding: 0;
  margin: 0;*/
}
.item:hover,
.item:focus {
  color: var(--primary-color);
}
.cover {
  /*width: 100%;
  height: 325px;*/
  aspect-ratio: 1200 / 400;
  margin-bottom: 0;
}
.cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
h2 {
  font-size: 50px;
    color: #fff;
    text-shadow:0 2px 5px #777777;
}
.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

.filterDropdown ul,
li {
  list-style: none;
  width: 270px;
}

[dir="ltr"] .filterDropdown ul {
  display: block;
  position: absolute;
  right: 5px;
  top: calc(100% + 30px);
  margin: 0 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
}
[dir="rtl"] .filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
}

/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
}

/*.navbar .dropdown ul a,*/

.filterDropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Araboto-Light";
  cursor: pointer;
}

/*.navbar .dropdown ul a i,*/

.filterDropdown ul a i {
  font-size: 12px;
}

/*.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,*/

.filterDropdown ul a:hover,
.filterDropdown ul .active:hover,
.filterDropdown ul li:hover > a {
  color: var(--primary-color);
}

/*.navbar .dropdown:hover>ul,*/

.filterDropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

/*.navbar .dropdown .dropdown ul,*/

.filterDropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

/*.navbar .dropdown .dropdown:hover>ul,*/

.filterDropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.vue-horizontal-list {
  background: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 50px;
}

.button {
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;
  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .mask {
    top: 40px;
  }
}
@media (max-width: 700px) {
  h2 {
    font-size: 30px;
  }

  .image1 {
    width: 40px;
    height: 40px;
   /* margin-right: 20px;
    margin-left: 20px;*/
  }
  /*.cover {
    height: 200px;
  }
  .mask {
    height: 200px;
  }*/
}

@media (max-width: 390px) {
  h2 {
    font-size: 20px;
  }

  .image1 {
    width: 20px;
    height: 20px;
   /* margin-right: 20px;
    margin-left: 20px;*/
  }
  /*.cover {
    height: 150px;
  }
  .mask {
    height: 150px;
  }*/

  [dir="ltr"] .filterDropdown ul {
    right: -20px;
  }
  [dir="rtl"] .filterDropdown ul {
    left: -20px;
  }
}
</style>
