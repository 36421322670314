<template>
  <!-- ======= Second Section ======= -->
  <section id="Second">
    <div class="container">
      <div class="d-flex flex-row justify-content-between">
        <div class="section-title">
          <h2>{{ $t("filters") }}</h2>
        </div>
        <div>
          <select class="select" @change="upadateproducts($event)">
            <option value="desc/id">{{ $t("sort1") }}</option>
            <option value="asc/id">{{ $t("sort2") }}</option>
            <option value="desc/sale_price">{{ $t("sort3") }}</option>
            <option value="asc/sale_price">{{ $t("sort4") }}</option>
            <option value="desc/count_view">{{ $t("sort5") }}</option>
            <option value="asc/count_view">{{ $t("sort6") }}</option>
            <option value="desc/quantity">{{ $t("sort7") }}</option>
            <option value="asc/quantity">{{ $t("sort8") }}</option>
            <option value="desc/count_sold">{{ $t("sort9") }}</option>
            <option value="asc/count_sold">{{ $t("sort10") }}</option>
          </select>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
      </div>
      <div v-else>
        <div class="row">
          <!--class="col-lg-2 col-md-3 col-sm-4 col-8 mb-5"-->
          <div class="col-lg-3 mb-5">
            <!--<div class="mb-3" v-for="(item, index) in this.DBitems" :key="index">-->
            <!--<router-link :to="(name='section')"><card1></card1></router-link>-->
            <!--<card1 :name="item.name" :image="item.icon" :sectionid="item.id"></card1>-->
            <treeselect
              class="treeselect"
              v-model="value"
              :multiple="true"
              :always-open="true"
              :default-expand-level="1"
              :disable-branch-nodes="true"
              :options="this.DBitems.sections"
              @input="handlePageChange(1)"
            />
             <div class="section-title mt-4">
          <h2>{{ $t("brands") }}</h2>
        </div>
            <treeselect
              class="treeselect"
              v-model="value2"
              :multiple="true"
              :always-open="true"
              :default-expand-level="1"
              :disable-branch-nodes="true"
              :options="this.DBitems.brands"
              @input="handlePageChange(1)"
            />
            <!--</div>-->
          </div>
          <div class="col-lg-9">
            <div class="row" v-if="!loadingProducts">
              <div
                class="col2 col-lg-3 col-md-4 col-6 mb-5"
                v-for="(item, index) in this.products"
                :key="index"
              >
                <card3
                  :name="item.name"
                  :description="item.description"
                  :is_favorit="item.is_favorit"
                  :image="item.image"
                  :old_price="item.old_price"
                  :new_price="item.new_price"
                  :productid="item.id"
                ></card3>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center mt-5">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="pageSize"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            pills
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </section>
  <!-- End Second -->
</template>

<script>
import card1 from "../components/CardStyle1All.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import card3 from "../components/CardStyle3.vue";

export default {
  components: {
    card1,
    card3,
    HollowDotsSpinner,
    HalfCircleSpinner,
    Treeselect
  },
  data() {
    return {
      DBitems: null,
      loading: false,
      loadingProducts: false,
      products: null,

      order_by: null,
      sort: null,
      page: 1,
      count: 0,
      pageSize: 12,
      langChange: "",
      //pageSizes: [3, 6, 9],

      // define the default value
      value: [],
      value2:[]
      // define options treeselect
      /* options: [ {
          id: 'a',
          label: 'a',
          children: [ {
            id: 'aa',
            label: 'aa',
          }, {
            id: 'ab',
            label: 'ab',
          } ],
        }, {
          id: 'b',
          label: 'b',
        }, {
          id: 'c',
          label: 'c',
        } ], */
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/sidebar_sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
      })
      .then(() => {
        this.loading = false;
      });
    //////////////////////products//////////////////////
    this.loadingProducts = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/categories_products",
        {
          categories_id: []
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loadingProducts = false));
    // this.axios
    //   .get(
    //     this.$store.state.base_api_url + this.$store.storeName + "/sections",
    //     {
    //       headers: {
    //         "Accept-Language": this.$i18n.locale
    //       }
    //     }
    //   )
    //   .then(response => {
    //     this.DBitems = response.data.data.sections;
    //     this.count = response.data.meta.total;
    //   })
    //   .then(() => {
    //     this.loading = false;
    //   });
  },
  watch: {
    langChange() {
      /*  this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        })
        .then(() => {
          this.loading = false;
        });*/
    }
  },
  updated() {
    this.langChange = this.$i18n.locale;
    /*  this.axios
        .get(
          "http://192.168.1.16:8000/api/" +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });*/
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loadingProducts = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/categories_products?page=" +
            params,
          {
            categories_id: this.value,
            brands_id: this.value2,
            orderby: this.order_by,
            sort: this.sort
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.products = response.data.data.products;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loadingProducts = false));
    },
      handlePageChange(value1) {
      this.page = value1;
      this.retrieveTutorials();
    },
    upadateproducts(event) {
      this.order_by = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      console.log(this.sort + "    " + this.order_by);

      this.loadingProducts = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/categories_products",
          {
            categories_id: this.value,
            brands_id: [],
            orderby: this.order_by,
            sort: this.sort
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.products = response.data.data.products;
          this.count = response.data.meta.total;
        })
        .then(() => (this.loadingProducts = false));
    }
  }
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 20px;
  min-height: 600px;
}

.section-title h2 {
  font-size: 14px;
  font-family: "Araboto-Black";
  color: #000;
}
.select{
font-size: 12px;
    font-family: "Araboto-Black";
    border: 0;
    padding: 0;
    max-width: 180px;
}
option{
  
    font-size: 12px;
    /*padding: 5px;*/
    font-family: "Araboto-Light";

}
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  .card {
    width: 100%;
  }
}
</style>
