<template>
    
    <!-- START SECTION BREADCRUMB -->
    <section>

<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{ $t('Wishlist') }}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item"><router-link to="/">{{ $t('home') }}</router-link></li>
                    <li class="breadcrumb-item active">{{ $t('Wishlist') }}</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- END CONTAINER-->
</div>


<!-- START SECTION SHOP -->

      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

<!-- END SECTION SHOP -->
<div class="section">
	<div class="container">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive wishlist_table">
                	<table class="table">
                    	<thead>
                        	<tr>
                            	<th class="product-thumbnail">&nbsp;</th>
                                <th class="product-name">{{ $t('product') }}</th>
                                <th class="product-price">{{ $t('price') }}</th>
                                <th class="product-stock-status">{{ $t('Stock Status') }}</th>
                                <th class="product-add-to-cart"></th>
                                <th class="product-remove">{{ $t('remove') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                        	<product v-for="(item, index) in this.products"
                          :name="item.name"
                         :image="item.image"
                            :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :is_option="item.is_option"
               :unit="item.unit"
              :qnt="item.qnt"
              :min_qnt="item.min_qnt"
              :max_qnt="item.max_qnt"
                          
                          >
                            
                            </product>
                           
                        </tbody>
                    </table>
                    <b-pagination
                v-if="this.products.length!=0"
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="pagination mt-3 justify-content-center pagination_style1"
                @change="handlePageChange"
              ></b-pagination>
        		
                </div>
            </div>
        </div>
    </div>
</div>



</section>
<!-- END SECTION BREADCRUMB -->

</template>



<script>
import product from "../components/favourite_row.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { product, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
        DBitems: null,
      loading: false,
      loading_product: false,
      products:[],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
    };
  },
  created() {
    this.loading = true;
    this.loading_product = true;
    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/favourite_product",
        
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
        this.count = response.data.meta.total;
        this.loading_product = false
      })
      .then(() => (this.loading = false ));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/favourite_product?page=" +
            this.page,
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading_product = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/favourite_product?page=" +
            params,
          
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },{
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
            }
          
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading_product = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },

    upadatesort(event)
  {
    this.order = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      this.filter_product();
  },
  filter_product()
    {
        console.log('brands array  ',this.brands_array);


        this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "theme5/favourite_product",
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },

  updatepaginate(event)
  {
    this.paginate = event.target.value

      this.filter_product();
  },
  change_style_product($type)
  {
   // this.element=document.getElementById('product_style');
    if ($type=='grid') {
			$('.shop_container').removeClass('list').addClass('grid');
      $('.grid').addClass('active').siblings().removeClass('active');
		//	this.element.addClass('active').siblings().removeClass('active');
		}
		else if($type=='list') {
			$('.shop_container').removeClass('grid').addClass('list');
			//this.element.addClass('active').siblings().removeClass('active');
      $('.list').addClass('active').siblings().removeClass('active');

		}
		$(".shop_container").append('<div class="loading_pr"><div class="mfp-preloader"></div></div>');
		setTimeout(function(){
			$('.loading_pr').remove();
			$container.isotope('layout');
		}, 800);
  }
  },
};
</script>