import { render, staticRenderFns } from "./sliderOffers.vue?vue&type=template&id=16c8d566&scoped=true&"
import script from "./sliderOffers.vue?vue&type=script&lang=js&"
export * from "./sliderOffers.vue?vue&type=script&lang=js&"
import style0 from "./sliderOffers.vue?vue&type=style&index=0&id=16c8d566&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c8d566",
  null
  
)

export default component.exports