<template>
    <section>
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

     
      
      <banners></banners>
      <shop_products></shop_products>
      <sections></sections>
      <brands></brands>
      
<!-- poupup -->
<Transition name="modal">
      <div v-if="this.$store.state.show_product" class="modal-mask product_mask">
        <div class="modal-wrapper product_wapper open">
          <div class="modal-container product_poup open">
            <div class="modal-header" style="border-bottom:none !important">
              <slot name="header"><i class="fa fa-close close_icon" 
                
                 @click="closepopup" ></i></slot>
             
            </div>
            <product_popup></product_popup>
          
          </div>
        </div>
      </div>
     

     
    </Transition>
<!-- end popup -->
      </section>

</template>
<script>
import banners from "../components/banners.vue";
import brands from "../components/brands.vue";

import shop_products from "../components/shop_products.vue";
import product_popup from "../components/product_popup.vue";

import sections from "../components/sections_home.vue"
export default {
  components: {
    banners,
    shop_products,
    sections,
    brands,
    product_popup
   },
  data() {
    return {
      DBitems: null,
      loading: false,
      products:[],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
    };
  },
  created() {
    this.loading = true;
    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products",
        
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/products?page=" +
            this.page,
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },
    
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/products?page=" +
            params,
          
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },{
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
            }
          
        )
        .then((response) => {
          this.DBitems = response.data.data.products;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    popup(id)
    {
      this.$store.state.productid_popup=id
      if(this.$store.state.show_product==false)
      this.$store.state.show_product=true;
    else
    this.$store.state.show_product=false;
    },
    closepopup()
    {
      this.$store.state.show_product=false;
    },
  
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },

    upadatesort(event)
  {
    this.order = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      this.filter_product();
  },
  filter_product()
    {
        console.log('brands array  ',this.brands_array);


        this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/products",
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },

  updatepaginate(event)
  {
    this.paginate = event.target.value

      this.filter_product();
  },
  change_style_product($type)
  {
   // this.element=document.getElementById('product_style');
    if ($type=='grid') {
			$('.shop_container').removeClass('list').addClass('grid');
      $('.grid').addClass('active').siblings().removeClass('active');
		//	this.element.addClass('active').siblings().removeClass('active');
		}
		else if($type=='list') {
			$('.shop_container').removeClass('grid').addClass('list');
			//this.element.addClass('active').siblings().removeClass('active');
      $('.list').addClass('active').siblings().removeClass('active');

		}
		$(".shop_container").append('<div class="loading_pr"><div class="mfp-preloader"></div></div>');
		setTimeout(function(){
			$('.loading_pr').remove();
			$container.isotope('layout');
		}, 800);
  }
  },
};
</script>
<style>
 
    .image_section_container
    {
        margin-bottom: 5%;;
    }

    .brand{
        width:150px;
        height:150px;
        
    }
    .brand_image{
        width:150px;
        height:150px;
        object-fit: cover;
        
    }
    .close_icon
    {
    float: left;
    direction: ltr;
    position: relative;
    top: 5%;
    left: 100%;
    margin-top: -2%;
    }
    [dir="rtl"] .close_icon
    {
    float: right;
    direction: rtl;
    position: relative;
    top: 5%;
    right: 100%;
    left:0;
    margin-top: -2%;
    
    }
    .product_mask
    {
      background: rgba(0, 0, 0, 0.9);
    }
    </style>