<template>
  <section id="Second">
    <div class="container">
      <div class="card">
        <div class="container">
          <div class="row">
            <div
              class="col-md-7"
              style="
                      background-image: url(https://media.istockphoto.com/id/1385695992/photo/businessmen-protecting-personal-data-on-laptop-and-virtual-interfaces.webp?b=1&s=170667a&w=0&k=20&c=VH8TXb5f70cZsmhcl-Lc9ao99vFaoq6xdbB3Dfmldhg=);
                      background-repeat: no-repeat;
                      background-size:cover;
                  "
            ></div>
            <div class="col-md-5 p-lg-5 pb-3 pt-3 ">
              <img class="logo" :src="this.$store.state.logo" alt />
              <h3 >{{ $t("CreateAccount") }}</h3>
              <form @submit.prevent="submitInfo" @keydown="form.onKeydown($event)">
                  <div>
                    
                    <div
                      v-if="errorMessage != ''"
                      style="
                      text-align: center;
                      padding: 5px;
                      background-color: #fff;
                      margin: 0 10px;
                    "
                    >
                      <span style="margin: 0; color: red; display: inline">
                        {{
                        errorMessage
                        }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="input1">
                      <h4>
                        {{ $t("name") }}
                        <i style="color: red">*</i>
                      </h4>
                      <input
                        id="name"
                        class="input-field"
                        type="text"
                        name="name"
                        autocomplete="false"
                        :placeholder="$t('EnterName')"
                        v-model="form.name"
                        required
                        maxlength="255"
                        minlength="3"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="input1" v-if="this.$store.state.auth == 1">
                      <h4>
                        {{ $t("Email") }}
                        <i style="color: red">*</i>
                      </h4>
                      <input
                        class="input-field"
                        type="email"
                        name="email"
                        autocomplete="false"
                        v-model="form.email"
                        placeholder="example@gmail.com"
                        required
                      />
                    </div>
                    <div class="input1" v-else id="userNumber">
                      <h4>
                        {{ $t("Phone") }}
                        <i style="color: red">*</i>
                      </h4>
                      <VuePhoneNumberInput
                        dir="ltr"
                        required
                        fetch-country
                        show-code-on-list
                        no-example
                        @update="sendPhone1($event)"
                        v-model="partphone"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="input1">
                      <h4>
                        {{ $t("password") }}
                        <i style="color: red">*</i>
                      </h4>
                      <input
                        id="password"
                        class="input-field"
                        type="password"
                        name="name"
                        autocomplete="new-password"
                        v-model="form.password"
                        :placeholder="$t('EnterPass')"
                        required
                        minlength="8"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="input1">
                      <h4>
                        {{ $t("passwordConfirm") }}
                        <i style="color: red">*</i>
                      </h4>
                      <input
                        id="passwordconfirm"
                        class="input-field"
                        type="password"
                        autocomplete="new-password"
                        name="name"
                        :placeholder="$t('EnterPass')"
                        required
                        v-model="confirmPassword"
                        minlength="8"
                      />
                    </div>
                  </div>
                  <!--editttttt check if match-->
                  <div class="d-flex flex-row justify-content-end">
                    <button
                      type="submit"
                      :disabled="form.busy"
                      class="button"
                    >{{ $t("CreateAccount") }}</button>
                  </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import testImage from "../../../public/assets/image/icons/defult.svg";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
    VuePhoneNumberInput
  },
  props: {
    phone: String,
    email: String
  },
  data: function() {
    return {
      isValid: false,
      DBitems: null,
      confirmPassword: null,
      errorMessage: "",
      partphone: null,
      form: new Form({
        name: null,
        /*email: null,*/
        password: null,
        phone: null,
        email: null,
        fsm_token: this.$store.state.fsm_token
      })
    };
  },
  created() {
    if (this.phone != null) {
      this.form.phone = this.phone;
      this.isValid = true;
    } else if (this.email != null) {
      this.form.email = this.email;
    }
    //console.log("hiiiiiiiiii" +this.form.phone    +   "   "  + this.form.email)
  },
  methods: {
    async submitInfo() {
      if (this.isValid == true) {
        if (this.confirmPassword == this.form.password) {
          const response = await this.form
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",

              {
                form: this.form,
                headers: {
                  "Accept-Language": this.$i18n.locale
                }
              }
            )
            .then(response => {
              if (response.data.code == "1") {
                //this.DBitems = response.data.data.token; //22|JaYzuFIY4eyIXjIh7haHEGxGS8xowdbnkElztmHh//44444444
                //this.$localStorage.set("userToken", response.data.data.token);
                //this.$localStorage.start();
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                //this.$localStorage.set("userToken", response.data.data.token);
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    //this.$localStorage.id() +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
                /* this.$router.push({
                  name: "home1",
                  params: { storeName: this.$store.storeName },
                });
                window.location.reload();*/
                window.location.href =
                  "https://dekakeen.com/store/" + this.$store.storeName;
              } else if (response.data.code == "-1") {
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
            });
        } else {
          if (this.$i18n.locale == "en") {
            this.errorMessage = "the password not matching";
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          } else {
            this.errorMessage = "كلمتا السر غير متطابقتين";
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessage = "your number isn't valid";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        } else {
          this.errorMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        }
      }
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.form.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    }
  }
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.input1 {
  width: 100%;
 /* width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;*/
}
#Second {
  margin-top: 10px;
}

input {
  border: 1px solid #d7d7d7;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  padding: 20px 5px 25px 5px;
  font-size: 14px;
}

h3 {
  font-size: 18px;
  font-family: "Araboto-Bold";
  color: #000;
  text-align: center;
}
h4 {
   font-size: 14px;
  font-family: "Araboto-normal";
  color: #6e6c6c;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 5px;
  width: 100%;
  padding: 10px 0 15px;
  text-align: center;
  border:0;
  color: #fff;
  font-size: 14px;
  font-family: "Araboto-normal";
  margin-top: 20px;
}

.button:hover {
   opacity: 0.9;
}


.card{
  max-width: 1000px;
  margin: auto;
}

.logo{
    margin-bottom: 20px;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
}

.icon {
  margin-top: -120px;
}

/*@media (min-width: 1400px){
.container {
    max-width: 1200px !important;
}
}*/
@media (max-width: 992px) {
  h4 {
    font-size: 15px;
  }
  .card-img-actions {
    margin-top: 40px;
  }
  .input1 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
  .button {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (max-width: 772px) {
  .input1 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
  }
  .button {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}
</style>
