<template>
  <!-- ======= Second Section ======= -->
  <section id="Second">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("sections") }}</h2>
      </div>
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
      </div>
      <div v-else>
        <div class="row">
          <!--class="col-lg-2 col-md-3 col-sm-4 col-8 mb-5"-->
          <div
            class="col-md-4
            
             mb-3"
            v-for="(item, index) in this.DBitems"
            :key="index"
          >
            <!--<router-link :to="(name='section')"><card1></card1></router-link>-->
            <card1 :name="item.name" :image="item.icon" :sectionid="item.id"></card1>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center mt-5">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="pageSize"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            pills
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </section>
  <!-- End Second -->
</template>

<script>
import card1 from "../components/CardStyle1All.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { card1, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      page: 1,
      count: 0,
      pageSize: 12,
      langChange:""
      //pageSizes: [3, 6, 9],
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data.sections;
        this.count = response.data.meta.total;
      })
      .then(() => {
        this.loading = false;
      });

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          this.pageSize=response.data.meta.per_page;

          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
    /*  this.axios
        .get(
          "http://192.168.1.16:8000/api/" +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });*/
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/sections?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 20px;
  min-height: 600px;
}



@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  .card {
    width: 100%;
  }
}
</style>
