<template>
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <product 
    :id="this.$route.params.id" 
    :name="this.$route.params.name "
     >
     </product>
  </div>
</template>
<script>
import Product from '../components/Product';
export default {
  name: "ProductPage",
  components: {
    Product
  },
  data() {
    return {
    };
  }
};
</script>
<style scoped>
</style>
