            <template>
               <div class="product" >
                <span v-if="this.new" class="pr_flash">{{ $t('new') }}</span>
                <span v-if="this.fetuered" class="pr_flash bg-danger">{{ $t('hot') }}</span>
                            <div class="product_img">
                                <router-link
                                :to="{
                                 name: 'product',
                                 params: {
                                 storeName: $store.storeName,
                                  id: productid  ,
                                   name:name
                                            },
                                   }"
                                >
                                    <img :src="image" 
                                         :alt="name"
                                         >
                                </router-link>
                                <div class="product_action_box">
                                    <ul class="list_none pr_action_btn">
                                        <li v-if="(!is_option)&&qnt!=0" 
                                        class="add-to-cart">
                                        <a @click="addToCart" ><i class="icon-basket-loaded"></i> {{ $t('Add To Cart') }}</a></li>
                                        <li>
                                            <a 
                                            @click="popup(productid)"
                                           
                                             class="popup-ajax">
                                                <i class="icon-magnifier-add"></i>
                                                </a></li>
                                        <li v-if="this.$localStorage.get(this.$store.storeName) != null">
                                          <a @click="changeFav" ><i v-if="this.fav"
                                           
                                        style="color:red !important" class="icon-heart"></i>
                                            <i 
                                            v-else
                                            class="icon-heart"></i>
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="product_info">
                                <h6 class="product_title">
                                    <router-link :to="{
                                 name: 'product',
                                 params: {
                                 storeName: $store.storeName,
                                  id: productid  ,
                                   name:name
                                            },
                                   }">
                                        {{name}}
                                        </router-link></h6>
                                       
                                <div  v-if="!is_option" class="product_price">
                                    <div v-if="new_price>0">
                                    <span class="price">{{ new_price.toFixed(2) }} {{ unit }} </span>
                                    <del>{{old_price.toFixed(2)}} {{ unit }} </del>
                                    <div v-if="is_offer" class="on_sale">
                                        <span>{{ discount }} % {{ $t('off') }}</span>
                                    </div>
                                    </div>
                                    <div v-else>
                                    <span class="price">{{ old_price.toFixed(2) }} {{ unit }}</span>
                                    
                                    </div>
                                   
                                   
                                    
                                </div>


                                <div  v-else class="product_price" >

                                <div  >
                             <span class="price">{{$t('PriceOnRequest')}}</span>
                                      </div>

                                                </div>
                                                <div class="row">
                                <div class="rating_wrap col-lg-6">
                                  <div class="star">
                  <div class="d-flex flex-row justify-content-center">
                    <span v-for="n in rate" :key="n">
                      <i class="fa fa-star rate" ></i>
                    </span>
                    <div class="d-flex flex-row justify-content-center">
                      <span v-for="n in 5 - rate" :key="n">
                        <i class="fa fa-star-o rate" style=""></i>
                      </span>
                      
                      <span class="rating_num"> ({{ count_view }})</span>
                      <i class="bi bi-eye"></i>
                    </div>
                   

                  </div>
                

                  <div>
                   
                  </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                <span v-if='qnt==0' class="no_qnt">{{$t('no_qnt') }}</span>
                                </div>
                                </div>
                            </div>


      <!-- popup -->
      <!-- <Transition name="modal" class="popup">
      <vodal
                      :show="this.show"
                      animation="fade"
                      @hide="popup"
                      :width="600"
                      :height="450"
                      style="margin-top: 20px"
                    >
                      <product_popup
                      :product_id="productid"

                      ></product_popup>
                    </vodal>
                  </Transition> -->
      <!-- end popup -->

      <div
     v-if="
      this.validateMessage !=''
  "
      style="
        position: fixed;
        z-index: 100;
        width: 280px;
        bottom: 50%;
        
        border-radius: 5px;
        background-color: #fff;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding-top: 5px;
        color:red
      "
    >
      <p>{{ validateMessage }}</p>
    </div>





                        </div>







</template>

<script>
import product_popup from "../components/product_popup.vue";

import Vodal from "vodal";
export default {
  components: { Vodal,product_popup },
  props: {
    qnt: Number,
    min_qnt: Number,
    max_qnt:Number,
    name: String,
    description: String,
    is_favorit: Boolean,
    is_option: Boolean,
    image: String,
    old_price: Number,
    new_price: Number,
    productid: Number,
    count_view: Number,
    unit: String,
    rate: Number,
    is_offer: Boolean,
    discount: Number,
    new:Boolean,
    fetuered:Boolean
    

  },
  data: function () {
    return {
      fav: false,
      loginMassage: null,
      show: false,
      validateMessage: '',
      finalOptions:0,
      login_msg:null
    };
  },
  created() {
    this.fav = this.is_favorit;
  },
  methods: {
    changeFav() {

    
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product_change/" +
            this.productid,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.fav = !this.fav;
          }
        });
    },
    popup(id)
    {
      this.$store.state.productid_popup=id
      if(this.$store.state.show_product==false)
      this.$store.state.show_product=true;
    else
    this.$store.state.show_product=false;
    },
    ////{ path: `/user/${id}` }
    productRoute() {
      if (this.$route.path.includes("/product")) {
        this.$router.push({
          name: "product",
          params: { id: this.productid, name: this.name },
        });
      } else {
        this.$router.push({
          name: "product",
          params: {
            storeName: this.$store.storeName,
            id: this.productid,
            name: this.name,
          },
        });
      }
    },

   
    addToCart() {
console.log('%%%%%');
var checked_true = [];

this.axios
  .post(
    this.$store.state.base_api_url +
      this.$store.storeName +
      "/check_cart_item",
    {
      product_id: this.productid,
      total_price: this.old_price,
      offer_price: this.new_price,
      quantity: 1,
      old_qnt:0,
      final_option: 0,
      option_check: []
    },
    {
      headers: {
        "Accept-Language": this.$i18n.locale
      }
    }
  )
  .then(response => {
    let product = null;
    this.validateMessage =response.data.message;
      setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);
    if (response.data.code == "1") {
      // let products = new Map;
      
      let S_name1 =
        this.$store.storeName +
        "/" +
        this.productid +
        '0' +
        checked_true;
      if (this.$cookies.isKey(S_name1)) {
      
        product = {
          product_id: this.productid,
          total_price:
            this.price + this.$cookies.get(S_name1).total_price,
          offer_price:
            this.offer_price + this.$cookies.get(S_name1).offer_price,
          quantity: Number(1) + Number(this.$cookies.get(S_name1).quantity),
          quantityAll: this.$cookies.get(S_name1).quantityAll,
          max_quantity: this.$cookies.get(S_name1).max_quantity,
          min_quantity: this.$cookies.get(S_name1).min_quantity,
          final_option: 0,
          option_check: checked_true,
          discount: this.DBitems.product.discount,
          note:this.$cookies.get(S_name1).note
        };
      } else {
        product = {
          product_id: this.productid,
          total_price: this.old_price,
          offer_price: this.new_price,
          quantity: Number(1),
          quantityAll: this.qnt,
          min_quantity: this.min_qnt,
          max_quantity: this.max_qnt,
          final_option: 0,
          option_check: checked_true,
          discount: this.discount,
          note: ''
        };
      }
     
      let S_name = this.$store.storeName + "/";

      if (
        !this.$cookies.isKey(
          S_name +
            product.product_id +
            product.final_option +
            product.option_check
        )
      ) {
        this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
      }

      this.$cookies.set(
        S_name +
          product.product_id +
          product.final_option +
          product.option_check,
        product
      );
    }
   

    this.$store.state.update_cart_list=true;

    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);
  });
},

    poupup_item()
  {
    $('.popup-ajax').magnificPopup({
		type: 'ajax',
		callbacks: {
			ajaxContentAdded: function() {
				carousel_slider();
				slick_slider();
			 }
		}
	});
  $('.content-popup').magnificPopup({
		type: 'inline',
		preloader: true,
		mainClass: 'mfp-zoom-in',
	}); 
  }
    
  },
  
};
</script>
<style>
.product_img img {
    width: 100%;
    aspect-ratio: 1/1.1 !important;
    object-fit: cover;
   
}
/* .vodal {
    position: absolute;
    right: 40%;
    top: 10px;
} */
.product{
  margin-left:1%;
  margin-right:1%;
}
.rate
{
  font-size:16px !important;
  color:#F6BC3E;
}
.no_qnt
{
  color:red;
  margin :0px 6px;
}
</style>