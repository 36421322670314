<template>
  <section id="Second">
    <div class="container">
      <div class="card">
        <div class="container">
          <div class="row">
            <div
              class="col-md-7"
              style="
                      background-image: url('/assets/image/register.jpg');
                      background-repeat: no-repeat;
                      background-size:cover;
                  "
            ></div>
            <div class="col-md-5 p-lg-5 pb-2 pt-4">
              <img class="logo" :src="this.$store.state.logo" alt />
              <h3>الاشتراك مجانا!</h3>
              <!--<div v-if=" this.$store.state.auth==1">
                    <p>{{ $t("inputPhone") }}</p>
                    <form @submit.prevent="sendPhone" @keydown="form.onKeydown($event)">
                      <div class="input1">
                        <VuePhoneNumberInput
                          dir="ltr"
                          required
                          fetch-country
                          show-code-on-list
                          no-example
                          @update="sendPhone1($event)"
                          v-model="partphone"
                        />
                      </div>
                      <div
                        v-if="validateMessage != ''"
                        style="text-align: center; margin-top: 10px"
                      >
                        <span style="color: red; margin: 0">{{ validateMessage }}*</span>
                      </div>
                      <button type="submit" :disabled="form.busy" class="button">
                        {{ $t("continue") }}
                      </button>
                    </form>
              </div>-->
              <div v-if=" this.$store.state.auth==0">
                <h4>{{ $t("inputEmail") }}</h4>
                <form @submit.prevent="sendEmail" @keydown="formEmail.onKeydown($event)">
                  <div class="input1">
                    <input
                      class="input-field"
                      type="email"
                      name="email"
                      autocomplete="false"
                      v-model="formEmail.email"
                      placeholder="example@gmail.com"
                      required
                    />
                  </div>
                  <div v-if="validateMessage != ''" style="text-align: center; margin-top: 10px">
                    <span style="color: red; margin: 0">{{ validateMessage }}*</span>
                  </div>
                  <button
                    type="submit"
                    :disabled="formEmail.busy"
                    class="button"
                  >{{ $t("continue") }}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";

export default {
  components: { VuePhoneNumberInput },
  data: function() {
    return {
      isValid: false,
      partphone: null,
      validateMessage: "",
      form: new Form({
        phone: null
      }),
      formEmail: new Form({
        email: null
      })
    };
  },
  /*mounted() {
    if (this.$i18n.locale == "ar") {
      document
        .getElementsByClassName("input-tel__input")[0]
        .setAttribute("placeholder", "رقم الهاتف");
      document.getElementsByClassName("country-selector__label")[0].innerHTML =
        "رمز الدولة";
      document.getElementsByClassName("input-tel__label")[0].innerHTML =
        "* رقم الهاتف";
    }
  },*/
  methods: {
    /*async sendPhone() {
      if (this.isValid == true) {
        await this.form
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/genret_code",
            {
              phone: this.form.phone,
              //country: this.form.country,
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            if (response.data.code == "-1") {
              console.log(response.data.code);
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.$router.push({
                name: "activateCode",
                params: {
                  storeName: this.$store.storeName,
                  phone: this.form.phone,
                },
              });
              //console.log( response.data.code);
              //console.log( response.data.message);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your number isn't valid";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },*/
    async sendEmail() {
      //if (this.isValid == true) {
      await this.formEmail
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/genret_code/byemail",
          {
            email: this.formEmail.email,
            //country: this.form.country,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "-1") {
            console.log(response.data.code);
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.$router.push({
              name: "activateCode",
              params: {
                storeName: this.$store.storeName,
                email: this.formEmail.email
              }
            });
            //console.log( response.data.code);
            //console.log( response.data.message);
          }
        });
      /*} else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your number isn't valid";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }*/
    }
    /*sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.form.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    },*/
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#Second {
  margin-top: 20px;
  min-height: 500px;
}

input {
    border: 1px solid #d7d7d7;
    width: 100%;
    height: 32px;
    border-radius: 5px;
    padding: 20px 5px 25px 5px;
    font-size: 14px;
}
h3 {
    font-size: 18px;
  font-family: "Araboto-Bold";
  color: #000;
  text-align: center;
}
h4 {
    font-size: 14px;
  font-family: "Araboto-normal";
  color: #6e6c6c;
  margin-top: 40px;
  margin-bottom: 10px;
}
p {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 15px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  text-align: center;
}
.button {
 background-color: var(--primary-color);
  border-radius: 5px;
  width: 100%;
  padding: 10px 0 15px;
  text-align: center;
  border:0;
  color: #fff;
  font-size: 14px;
  font-family: "Araboto-normal";
  margin-top: 20px;
}

.button:hover {
  opacity: 0.9;
}
.card{
  max-width: 1000px;
  margin: auto;
}
p{
  font-size: 14px;
}

.logo{
    margin-bottom: 20px;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
}
.input1 {
  width: 100%;
  margin-bottom: 20px;
}
/*@media (min-width: 1400px){
.container {
    max-width: 1200px !important;
}
}*/
@media (max-width: 992px) {
  h4 {
    font-size: 15px;
  }
  .card-img-actions .preview-1 {
    margin-top: 30px;
    width: 120px !important;
    height: 120px !important;
  }
  .card-img-actions {
    margin-top: 40px;
  }
  .input1 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
  .button {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (max-width: 772px) {
  .input1 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
  }
  .button {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
  p {
    font-size: 12px;
  }
}
</style>
