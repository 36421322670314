<template>
  <section id="Second" v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 mb-4 mobile-inactive ">
          <div class="card1">
            <router-link :to="(name = 'profile')">
              <i class="bi bi-person-circle"></i>
              {{$t("profile")}}
            </router-link>
            <router-link :to="(name = 'myOrders')">
              <i class="bi bi-bag"></i>
              {{$t("myOrders")}}
            </router-link>
            <router-link :to="(name ='favorites')">
              <i class="bi bi-heart"></i>
              {{ $t("favorites") }}
            </router-link>
            <router-link :to="(name = 'myAddresses')">
              <i class="bi bi-geo-alt"></i>
              {{ $t("myAddresses") }}
            </router-link>
            <router-link :to="(name = 'notifications')">
              <i class="bi bi-bell"></i>
              {{ $t("Notifications") }}
            </router-link>
            <router-link
              :to="{
                    name: 'home1',
                    params: { storeName: this.$store.storeName },
                  }"
              @click.native="logOut()"
            >
              <i class="bi bi-box-arrow-right"></i>
              {{ $t("logOut") }}
            </router-link>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 mb-4">
          <div class="container">
            <div class="card1" style="padding:25px 30px 20px 30px">
              <div class="container">
                <div class="section-title">
                  <h2>{{ $t("favorites") }}</h2>
                </div>
                <div
                  v-if="loading"
                  class="d-flex flex-row justify-content-center"
                  style="margin-top: 100px; margin-bottom: 200px"
                >
                  <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="35"
                    :dots-num="3"
                    color="var(--primary-color)"
                  />
                </div>
                <div v-else>
                  <div class="row">
                    <div
                      class="collg6 col-lg-4 col-sm-6 col-6 mb-5"
                      v-for="(item, index) in this.DBitems"
                      :key="index"
                    >
                      <card3
                        :name="item.name"
                        :description="item.description"
                        :is_favorit="item.is_favorit"
                        :image="item.image"
                        :old_price="item.old_price"
                        :new_price="item.new_price"
                        :productid="item.id"
                      ></card3>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count>1">
                    <b-pagination
                      v-model="page"
                      :total-rows="count"
                      :per-page="pageSize"
                      :prev-text="$t('prev')"
                      :next-text="$t('next')"
                      pills
                      @change="handlePageChange"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Second -->
</template>


<script>
import card3 from "../components/CardStyle3.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { card3, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      page: 1,
      count: 0,
      pageSize: 12,
      langChange: ""
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/favourite_product",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.products;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "https://dekakeen.com/store/" + this.$store.storeName;
        }
      });
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.products;
          //this.count = response.data.meta.total;
        })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    }
  },
  updated() {
    this.langChange = this.$i18n.locale;
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.products;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.section-title h2 {
    font-size: 18px;
    font-family: "Araboto-Black";
    color: var(--primary-color);
}
#Second {
  margin-top: 20px;
  min-height: 500px;
}
.card1 {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  height: 100%;
}
a {
  display: block;
  padding: 13px 10px 17px 10px;
  width: 100%;
  color: #000;
  font-family: "Araboto-normal";
  font-size: 14px;
  text-align: start;
}
a i {
  padding: 0 10px;
  color: #acacac;
  font-size: 16px;
}
a:hover {
  color: #000 !important;
}
.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #f8f8f8;
  color: #000 !important;
  /*border: 2px solid var(--primary-color);*/
}
.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

/*@media (min-width: 2000px) {
  .collg6 {
    width: 15%;
  }
}
*/
@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .card1{
    padding: 25px 5px 20px !important;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
}
</style>
