<template>
    <section id="About">
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
      <div class="container" v-html="DBitems" >
      </div>
    </section>
  </template>
  <script>
  export default {
    components:{},
    data: function () {
      return {
        loading: false,
        DBitems: null,
      };
    },
    created() {
      this.loading = true;
      this.axios
      .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/pages/" +this.$route.params.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.description;
        })
        .then(() => (this.loading = false));
    },
    updated() {
      this.loading = true;
      this.axios
      .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/pages/" +this.$route.params.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.description;
        })
        .then(() => (this.loading = false));
        if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    },
    methods: {
      updatedata() {
        this.loading = true;
      this.axios
      .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/pages/" +this.$route.params.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.description;
        })
        .then(() => (this.loading = false));
      },
    },

    watch: {
    "$route.params": {
      handler(newValue) {
        this.loading = true;
      this.axios
      .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/pages/" +this.$route.params.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.description;
        })
        .then(() => (this.loading = false));
      },
      immediate: true
    }
  }
  };
  </script>
  
  <style scoped>
  #About {
  padding-top: 150px;
  min-height: 600px;
}
  </style>


  
  