<template>
  <!-- ======= Second Section ======= -->
  <section id="Second">
    <div class="container">
      <div class="section-title" style="margin-bottom: 40px">
        <div class="barndImg">
          <img :src="image" />
        </div>
        <h2 style="display: inline; margin: 10px">{{ name }}</h2>
      </div>
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
      </div>
      <div v-else>
        <div class="row">
          <div
            class="collg6 col-lg-3 col-6 mb-5"
            v-for="(item, index) in this.DBitems"
            :key="index"
          >
            <card3
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
            ></card3>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center mt-5">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="pageSize"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            pills
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </section>
  <!-- End Second -->
</template>
  
  
  <script>
import card3 from "../components/CardStyle3.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { card3, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      image: null,
      name: "",
      page: 1,
      count: 0,
      pageSize: 12,
      langChange: "",
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/products/brand/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data.products;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/brand/" +
              this.$route.params.id,
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            this.name = response.data.data.brand;
            this.image = response.data.data.image;
          });
        this.count = response.data.meta.total;
        this.pageSize=response.data.meta.per_page;

      })
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/products/brand/" +
            this.$route.params.id +
            "?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.products;
          this.axios
            .get(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/brand/" +
                this.$route.params.id,
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                  Authorization:
                    "Bearer " + this.$localStorage.get(this.$store.storeName),
                },
              }
            )
            .then((response) => {
              this.name = response.data.data.brand;
              this.image = response.data.data.image;
            });

          //this.count = response.data.meta.total;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  updated() {
    this.langChange = this.$i18n.locale;
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/products/brand/" +
            this.$route.params.id +
            "?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.products;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
            console.log(params);
            console.log(e);
          });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
  },
};
</script>
  
  <style scoped>
/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/

#Second {
  margin-top: 20px;
  min-height: 600px;
}
.barndImg {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: inline-block;
  box-shadow: 0 0 20px #8d8d8d;
}
.barndImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
}
.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

/*@media (min-width: 2000px) {
    .collg6 {
      width: 15%;
    }
  }
  */
@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  .barndImg {
    width: 40px;
    height: 40px;
  }
}
</style>
  