<template>
    <section>
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
      
        <!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{$t('contactUs')}}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('home') }}
                            </router-link></li>
                    <li class="breadcrumb-item active">{{$t('contactUs')}}</li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->


<!-- START MAIN CONTENT -->
<div class="main_content">

<!-- START SECTION CONTACT -->
<div class="section pb_70">
	<div class="container">
        <div class="row">
            <div class="col-xl-4 col-md-6">
            	<div  v-if="this.DBitems.social.address" class="contact_wrap contact_style3">
                    <div class="contact_icon">
                        <i class="linearicons-map2"></i>
                    </div>
                    <div class="contact_text">
                        <span>{{$t('Address')}}</span>
                        <p>{{ this.DBitems.social.address }}</p>
                    </div>
                </div>
            </div>
            <div  v-if="this.DBitems.social.email" class="col-xl-4 col-md-6">
            	<div class="contact_wrap contact_style3">
                    <div class="contact_icon">
                        <i class="linearicons-envelope-open"></i>
                    </div>
                    <div class="contact_text">
                        <span>{{ $t('Email') }}</span>
                        <a :href=this.DBitems.social.email>{{ this.DBitems.social.email  }}</a>
                    </div>
                </div>
            </div>

            <div v-if="this.DBitems.social.phone" class="col-xl-4 col-md-6">
            	<div class="contact_wrap contact_style3">
                    <div class="contact_icon">
                        <i class="linearicons-tablet2"></i>
                    </div>
                    <div class="contact_text">
                        <span>{{ $t('phone') }}</span>
                        <p dir="ltr">{{ this.DBitems.social.phone}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION CONTACT -->

<!-- START SECTION CONTACT -->
<div class="section pt-0">
	<div class="container">
    	<div class="row">
        	<div class="col-lg-6">
            	<div class="heading_s1">
                	<h2>{{ $t('Get In touch') }}</h2>
                </div>
                <p class="leads"> {{ $t("LeaveMessage") }}</p>
                <div class="field_form">
                    <form @submit.prevent="submitmessage" @keydown="form.onKeydown($event)">

                        <div class="row">
                            <div class="form-group col-md-6">
                                <input 
                                required 
                                id="first-name"
                                 class="form-control" 
                               
                                 type="text"
                                 :placeholder="$t('Enter Name')"
                                  v-model="form.name"
                                 >
                             </div>
                            <div class="form-group col-md-6">
                                <input 
                                required 
                                 id="email"
                                  class="form-control" 
                                  
                                  type="email"
                                  :placeholder="$t('Enter email')"
                                  v-model="form.email"
                                  >
                            </div>
                            <div class="form-group col-md-6">
                                <input
                                 required
                                   id="phone" 
                                   :placeholder="$t('Enter phone')"
                                   class="form-control" 
                                   v-model="form.phone"
                                   >
                            </div>
                            <div class="form-group col-md-6">
                                <input
                                 :placeholder="$t('Enter Subject')"
                                   id="subject"
                                   class="form-control"
                                   name="subject">
                            </div>

                            <div class="form-group col-md-12">
                                <textarea
                                 required
                                   id="description" 
                                   class="form-control"
                                   :placeholder="$t('message') "
                                    v-model="form.message"
                                     rows="4"
                                     ></textarea>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" title="Submit Your Message!" class="btn btn-fill-out" id="submitButton" name="submit" value="Submit">
                                    {{ $t('SendMessage') }}
                                    </button>
                            </div>
                            <div class="col-md-12">
                                <div id="alert-msg" class="alert-msg text-center">

                                    <AlertSuccess :form="form" :message="$t('Message1')" />
                                   <AlertErrors :form="form" :message="$t('Message0')" />
                                </div>
                            </div>
                        </div>
                    </form>		
                </div>
            </div>
            <div class="col-lg-6 pt-2 pt-lg-0 mt-4 mt-lg-0">
            	<div >
                
                
                    <GmapMap
          :center="center"
          :zoom="12"
          map-style-id="roadmap"
          :options="mapOptions"
          class="contact_map2"
          style="width: 100%;height:500px"
          ref="mapRef"
        >
          <GmapMarker
            :position="marker.position"
            icon="/pet_shop/assets/images/marker.png"
            :clickable="false"
            :draggable="false"
            :animation="1"
           
          />
        </GmapMap>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION CONTACT -->


</div>
<!-- END MAIN CONTENT -->
        </section>


</template>


<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,

      form: new Form({
        name: null,
        email: null,
        message: null
      }),

      marker: { position: {  lat: 51.677878,
          lng: 30.67676 } },
      center: { lat: 51.677878,
          lng: 30.67676 },
      mapOptions: {
        disableDefaultUI: true
      }
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/contact_us",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
        this.marker.position = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
        this.center = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
      })
      .then(() => (this.loading = false));

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    init() {
        var map_selector = $('#map');

		
		var mapOptions = {
			zoom: map_selector.data("zoom"),
			mapTypeControl: false,
			center: new google.maps.LatLng(map_selector.data("latitude"), map_selector.data("longitude")), // New York
		  };
		var mapElement = document.getElementById('map');
		var map = new google.maps.Map(mapElement, mapOptions);
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(map_selector.data("latitude"), map_selector.data("longitude")),
			map: map,
			icon: map_selector.data("icon"),
			
			title: map_selector.data("title"),
		});
		marker.setAnimation(google.maps.Animation.BOUNCE);
	}	,
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/contact_us",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));
      this.$store.state.langchange = false;
    },
    async submitmessage() {
      const response = await this.form
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/send_message",
          {
            message: this.form.message,
            name: this.form.name,
            email: this.form.email
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.form.message = "";
            this.form.name = "";
            this.form.email = "";
            console.log(response.data.message);
          }
          if (response.data.error) {
            this.errorMessage = response.data.message;
          }
        });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    }
  }
};
</script>