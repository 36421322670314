<template>
  <section
    id="Second"
    v-if="this.$localStorage.get(this.$store.storeName) != null"
  >
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div class="container" v-if="items.length != 0">
      <div
        class="d-flex flex-row justify-content-between align-items-center mb-5"
      >
        <div class="section-title" style="padding-bottom: 0">
          <h2>{{ $t("confirmOrder") }}</h2>
        </div>
        <div class="card2 text-center" style="width: auto; margin: 0">
          <h4>
            {{ $t("CartProducts") }}
            <span style="color: #9c0008">{{ items.length }}</span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div
          class="collg4 col-lg-4 col-md-6 mb-4"
          v-for="item in this.items"
          :key="item"
        >
          <card5 :item-id="item"></card5>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-8 mb-4">
          <div class="mt-1">
            <h4 style="margin-bottom: 30px">{{ $t("DeliveryMethod") }}</h4>
            <div class="row">
              <div
                class="col-md-6 mb-4"
                v-for="(item, index) in this.DBitems.delivery_type.types"
                :key="index"
              >
                <button
                  :class="item.isActive ? 'card1 active' : ' card1'"
                  @click="selectDeliveryTypeId(item.id)"
                >
                  <div class="row">
                    <div class="col-3">
                      <img
                        style="max-width: 100%; object-fit: contain"
                        :src="item.icon"
                      />
                    </div>
                    <div class="col-9 mt-3">
                      <h6>{{ item.type }}</h6>
                      <p>{{ item.description }}</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h4 style="margin-bottom: 30px">{{ $t("PaymentMethod") }}</h4>
            <div class="row">
              <div
                class="col-md-6 mb-4"
                v-for="(item, index) in this.DBitems.payment_types.types"
                :key="index"
              >
                <button
                  :class="item.isActive ? 'card1 active' : ' card1'"
                  @click="selectPaymentTypeId(item.id)"
                >
                  <div class="row">
                    <div class="col-3">
                      <img
                        style="max-width: 100%; object-fit: contain"
                        :src="item.icon"
                      />
                    </div>
                    <div class="col-9 mt-3">
                      <h6>{{ item.type }}</h6>
                      <p>{{ item.description }}</p>
                    </div>
                  </div>
                </button>
              </div>
              <!--<div class="col-lg-4 col-md-6 mb-4">
            <div class="card1">
              <div class="row">
                <div class="col-3">
                  <img src="../../public/assets/image/icons/visa.svg" />
                </div>
                <div class="col-9 mt-3">
                  <h6>دفع الكتروني بالبطاقة</h6>
                  <p>ادفع كلفة طلبك الآن عن طريق البطاقة</p>
                </div>
              </div>
            </div>
              </div>-->
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4 information">
          <div v-if="showMonthes" class="mt-2">
            <h4 style="margin-bottom: 30px">{{ $t("select_count_month") }}</h4>
            <select
              id="monthsSelect"
              class="select"
              :title="$t('months')"
              v-if="DBitems.months.length != 0"
              @change="onChangeMonths($event)"
            >
              <option hidden selected value="SelectOne">
                {{ $t("SelectOne") }}
              </option>
              <option
                v-for="(item, index) in DBitems.months"
                :key="index"
                :value="item"
              >
                {{ item }} {{ $t("months") }}
              </option>
            </select>
          </div>
          <div v-if="showAddress" class="mt-2">
            <h4 style="margin-bottom: 30px">{{ $t("Address") }}</h4>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <select
                  id="addressSelect"
                  class="select"
                  :title="$t('Address')"
                  v-if="addresses.length != 0"
                  @change="onChange($event)"
                >
                  <option hidden selected value="SelectOne">
                    {{ $t("SelectOne") }}
                  </option>
                  <option
                    v-for="(item, index) in addresses"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
              <div class="col-sm-6 mb-4">
                <div>
                  <button class="addAddress" @click="addAddress = true">
                    {{ $t("AddNewAddress") }}
                  </button>
                  <vodal
                    :show="addAddress"
                    animation="flip"
                    @hide="addAddress = false"
                    :width="700"
                    :height="400"
                  >
                    <div style="height: 350px; overflow: scroll">
                      <add-address></add-address>
                    </div>
                  </vodal>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-4 mt-2">
            <h5>{{ $t("Coupon") }}</h5>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <input
                  type="text"
                  class="form-control code-input"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  v-model="disCode"
                  :placeholder="$t('Couponcode')"
                />
                <span>
                  <i
                    style="color: #24b300; font-size: 15px; margin-left: -75%"
                    class="bi bi-check-circle-fill"
                  ></i>
                </span>
              </div>
              <div class="col-sm-6 mb-4">
                <button class="activate" @click="Couponcode()">
                  <h4>{{ $t("Activate") }}</h4>
                </button>
              </div>
            </div>
            <span>{{ couponMsg }}</span>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <h5>{{ $t("subTotal") }} :</h5>
            <h5 v-if="$i18n.locale == 'ar'" style="color: #9c0008">
              {{ PartialPrice.toFixed(2) }} {{ this.$store.state.unitAR }}
            </h5>
            <h5 v-else style="color: #9c0008">
              {{ PartialPrice.toFixed(2) }} {{ this.$store.state.unit }}
            </h5>
          </div>
          <div
            v-if="this.discountPrice != 0"
            class="d-flex flex-row justify-content-between"
          >
            <h5>{{ $t("discount") }} ({{ discountPrice }} %) :</h5>
            <h5 v-if="$i18n.locale == 'ar'" style="color: #004e1f">
              {{ ((this.discountPrice * this.PartialPrice) / 100).toFixed(2) }}
              {{ this.$store.state.unitAR }}
            </h5>
            <h5 v-else style="color: #004e1f">
              {{ ((this.discountPrice * this.PartialPrice) / 100).toFixed(2) }}
              {{ this.$store.state.unit }}
            </h5>
            <!--<span v-else style="color: #004e1f; font-size: 10px">
            {{
              $t("entercode")
            }}
            </span>-->
          </div>

          <div
            v-if="this.selectDeliveryType == 5"
            class="d-flex flex-row justify-content-between"
          >
            <h5>{{ $t("deliveryPrice") }} :</h5>
            <h5 v-if="$i18n.locale == 'ar'" style="color: #9c0008">
              {{ deliveryPrice.toFixed(2) }} {{ this.$store.state.unitAR }}
            </h5>
            <h5 v-else-if="$i18n.locale == 'en'" style="color: #9c0008">
              {{ deliveryPrice.toFixed(2) }} {{ this.$store.state.unit }}
            </h5>
            <!--<span v-else style="color: #004e1f; font-size: 10px">
            {{
              $t("selectAddress")
            }}</span>-->
          </div>
          <div
            v-if="$store.state.tax != null"
            class="d-flex flex-row justify-content-between"
          >
            <h5>{{ $t("tax") }} ( {{ $store.state.tax }}% ):</h5>
            <h5 v-if="$i18n.locale == 'ar'" style="color: #004e1f">
              {{
                (
                  (this.$store.state.tax *
                    (this.PartialPrice -
                      (this.discountPrice * this.PartialPrice) / 100 +
                      this.deliveryPrice)) /
                  100
                ).toFixed(2)
              }}
              {{ this.$store.state.unitAR }}
            </h5>
            <h5 v-else style="color: #004e1f">
              {{
                (
                  (this.$store.state.tax *
                    (this.PartialPrice -
                      (this.discountPrice * this.PartialPrice) / 100 +
                      this.deliveryPrice)) /
                  100
                ).toFixed(2)
              }}
              {{ this.$store.state.unit }}
            </h5>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <h5 style="font-size: 18px">{{ $t("totalPrice") }} :</h5>
            <h5
              v-if="$i18n.locale == 'ar'"
              style="color: #000; font-size: 18px"
            >
              {{ totalPrice.toFixed(2) }} {{ this.$store.state.unitAR }}
            </h5>
            <h5 v-else style="color: #000; font-size: 18px">
              {{ totalPrice.toFixed(2) }} {{ this.$store.state.unit }}
            </h5>
          </div>
          <div>
            <h5>{{ $t("note") }} :</h5>
            <textarea
              rows="3"
              style="width: 100%"
              v-model="allOrderNote"
            ></textarea>
          </div>
        </div>
      </div>
      <div v-if="!loadingMethods && this.DBitems != null">
        <div class="d-flex flex-row justify-content-end mt-4">
          <button class="button" @click="confirmOrder()">
            {{ $t("confirmOrder") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import card5 from "../components/CardStyle5.vue";
import AddAddress from "../components/AddAddress.vue";
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

export default {
  components: {
    Vodal,
    card5,
    AddAddress,
  },
  data: function () {
    return {
      items: [],
      products: [],
      products_cost: [],
      loading: false,
      loadingMethods: false,
      addresses: [],
      addAddress: false,
      showAddress: false,
      showMonthes: false,
      DBitems: null,
      discount_delivery_free	:0,
      selectPaymentType: null,
      selectDeliveryType: null,
      selectAddress: null,
      count_month: null,
      disCode: null,
      discount_id: null,
      PartialPrice: 0,
      discountPrice: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      couponMsg: "",

      allOrderNote: "",
    };
  },
  created() {
    let AllCookies = this.$cookies.keys();
    this.items = [];
    this.products = [];
    this.products_cost=[];
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        ////PartialPrice
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
        }
        // console.log('products',pluck(this.products,"product_id"));
      }
    }

    for (let i = 0; i < this.products.length; i++) {
      this.products_cost.push(this.products[i]['product_id']);
    }
    console.log('product_cost: ',this.products_cost);

    ///////////addresses
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.addresses = response.data.data.addresses.addresses;
        ////selectAddress
        /*if (this.addresses.length != 0) {
          this.selectAddress = this.addresses[0].id;
        }*/
        // console.log(this.addresses);
      })
      .then((this.loading = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href = "/" ;
        }
      });
    ///////////methods
    this.loadingMethods = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
        // console.log(this.addresses);
      })
      .then((this.loadingMethods = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href = "/";
        }
      });

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    PartialPrice() {
      //$store.state.tax
      //console.log("this.$store.state.tax "+ this.$store.state.tax + "  " +this.$store.state.tax+  this.PartialPrice)
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  let x= this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 );
            this.totalPrice = x + ( (this.$store.state.tax *  x) / 100 ) +this.deliveryPrice;*/
      /* this.totalPrice =
               this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("PartialPrice Change" + this.totalPrice);*/
    },
    discountPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /* this.totalPrice =
             this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("discountPrice Change" + this.totalPrice);*/
    },
    deliveryPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  this.totalPrice =
              this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
              console.log("deliveryPrice Change" + this.totalPrice);*/
    },
    selectDeliveryType() {
      if (this.selectDeliveryType == 5) {
        this.showAddress = true;
        //console.log("this.showAddress" + this.showAddress);
      } else {
        this.showAddress = false;
      }
    },
    selectPaymentType() {
      if (this.selectPaymentType == 4) {
        this.showMonthes = true;
      } else {
        this.showMonthes = false;
        this.count_month = null;
      }
      //console.log( this.count_month)
    },
    addAddress() {
      this.selectDeliveryTypeId(5);
    },
  },
  methods: {
    updatAddress() {
      ///////////addresses
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.addresses = response.data.data.addresses.addresses;
          /*if (this.addresses.length != 0) {
            this.selectAddress = this.addresses[0].id;
          }*/
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    updatedata() {
      this.loadingMethods = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/order/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          if (this.selectPaymentType != null) {
            this.selectPaymentTypeId(this.selectPaymentType);
          }
          if (this.selectDeliveryType != null) {
            for (let el = 0;el < this.DBitems.delivery_type.types.length; el++) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id ==this.selectDeliveryType
              ) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
          }
          //console.log(this.selectPaymentType + " hoiiiiiiiiiii");
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response != null) {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/" ;
            }
          }
        });

      this.$store.state.langchange = false;
    },
    Couponcode() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/use_coupon",
          {
            code: this.disCode,
            total_price: this.PartialPrice,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.couponMsg = "";
            this.discountPrice = response.data.data.discount;
            this.discount_id = response.data.data.discount_id;
            this.discount_delivery_free=response.data.data.isdelivery_free;
            if(this.discount_delivery_free==1)
            this.deliveryPrice=0;
            /////////for select
           // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          } else {
            this.couponMsg = response.data.message;
            /////////for select
           // this.selectAddress = null;
           // this.deliveryPrice = 0;
            ////////////
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/" ;
          }
        });
    },
    selectPaymentTypeId(id) {
      this.loadingMethods = true;
      this.selectPaymentType = id;
      //console.log(this.selectPaymentType);

      for (let el = 0; el < this.DBitems.payment_types.types.length; el++) {
        this.DBitems.payment_types.types[el].isActive = false;
        if (this.DBitems.payment_types.types[el].id == id) {
          this.DBitems.payment_types.types[el].isActive = true;
        }
        //console.log(this.DBitems.payment_types.types[el].isActive);
      }

      //setTimeout(() => {
      this.loadingMethods = false;
      //  }, 3000);
    },
    selectDeliveryTypeId(id) {
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/cost_delivery",
          {
            address_id: this.selectAddress,
            delivery_id: id,
            cost:this.PartialPrice,
            products:this.products_cost,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            if(this.discount_delivery_free==0){
              // console.log(this.discount_delivery_free);
            this.deliveryPrice = response.data.data.cost;
            }
            this.selectDeliveryType = id;
            //console.log(this.selectDeliveryType);
            for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == id) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
            // this.discountPrice = response.data.data.discount;
          } else {
            alert(response.data.message);
            this.selectAddress = null;

            const $select = document.querySelector("#addressSelect");
            $select.value = "SelectOne";
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then(() => {
          this.loadingMethods = false;
        })
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    onChange: function (event) {
      this.selectAddress = event.target.value;
      this.selectDeliveryTypeId(5);
    },
    onChangeMonths: function (event) {
      //console.log( event.target.value)
      this.count_month = event.target.value;
    },
    confirmOrder() {
      if (this.selectPaymentType == null) {
        if (this.$i18n.locale == "ar") {
          alert("يجب تحديد طريقة الدفع");
        } else {
          alert("Please select a payment type");
        }
      } else if (this.selectDeliveryType == null) {
        if (this.$i18n.locale == "ar") {
          alert("يجب تحديد طريقة التوصيل");
        } else {
          alert("Please select a delivery type");
        }
      } else {

        document.getElementById("btn_confirm").disabled = true;

        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/order/store",
            {
              address_id: this.selectAddress,
              delivery_type_id: this.selectDeliveryType,
              payment_type_id: this.selectPaymentType,
              total_price: this.totalPrice,
              discount_id: this.discount_id,
              products: this.products,
              part_price: this.PartialPrice,
              note: this.allOrderNote,
              count_month: this.count_month,
              device:'web',
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              /* alert(response.data.message);*/
              if(response.data.data.clear_cart==null||response.data.data.link_pay == null)
              {
              for (let i = 0; i < this.items.length; i++) {
                this.$cookies.remove(this.items[i]);
              }
              this.items = [];

              console.log("hiiiiiiii" + this.$cookies.keys());
              this.$store.state.cartCounter = 0;
            }

              //console.log("hiiiiiiii" + this.$cookies.keys());

              if (response.data.data.link_pay == null) {
                this.$store.state.cartCounter = 0;
              this.$router.push({
                name: "myOrders",
                params: {
                  storeName: this.$store.storeName,
                },
              });
              }
             
              if (response.data.data.link_pay != null) {
                window.location.href = response.data.data.link_pay;
              }

              //this.deliveryPrice = response.data.data.cost;
              // this.discountPrice = response.data.data.discount;
              document.getElementById("btn_confirm").disabled = false;

            } else {
              alert(response.data.message);
              document.getElementById("btn_confirm").disabled = false;

              // this.couponMsg = response.data.message;
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/" ;
            }
          });
      }
    },
  },
};
/*var btns = document.getElementsByClassName("card1");
console.log("hoooooooo")
for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("active");
        if (current.length > 0) {
            current[0].className = current[0].className.replace(" active", "");
        }
        this.className += " active";
    });
}*/
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 120px;
  min-height: 600px;
}

.addAddress {
  /*margin-top: 10px;*/
  border: 2px solid #000;
  /*border: 2px solid transparent;*/
  background-color: #fff;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Araboto-Bold";
  padding: 10px;
  height: 50px;
  width: 100%;
  /* box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);*/
}

.addAddress {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

.addAddress:hover {
  transform: scale(1.05);
}

h4 {
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: #000;
}

h5 {
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: #000;
  margin-bottom: 10%;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 20%;
  text-align: center;
  padding: 10px 0 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.code-input {
  text-align: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #000;
  display: inline;
}

[dir="rtl"] .bi-check-circle-fill {
  position: relative;
  left: 18%;
}

[dir="ltr"] .bi-check-circle-fill {
  position: relative;
  right: 18%;
}

.activate {
  text-align: center;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #000;
}

.activate:hover {
  border: 2px solid #24b300;
  background-color: #24b300;
}

#Second select {
  border: 2px solid #000;
  /*border: 0;*/
  background-color: #fff;
  /*box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);*/
  border-radius: 10px;
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: var(--secondary-color);
  text-align: center;
  width: 100%;
  height: 50px;
}

.card1 {
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 10px;
  padding: 10px;

  width: 100%;
  height: 100px;
}

.card1:hover,
.card1.active {
  border: 2px solid var(--primary-color);
  background: linear-gradient(#fff, var(--primary-color) 200%);
  /*box-shadow: none;*/
}

.card1 h6 {
  font-size: 13px;
  font-family: "Araboto-Bold";
  color: #000;
  text-align: start;
}

.card1 p {
  font-size: 11px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  text-align: start;
}

.card2 {
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 10px;
  padding: 10px;
}

/*@media (min-width: 1500px) {
  .collg4 {
    width: 25%;
  }
}
@media (min-width: 2000px) {
  .collg4 {
    width: 20%;
  }
}*/
@media (min-width: 1200px) {
  .col-lg-8 {
    width: 60%;
    margin: 0 3%;
  }
}

@media (max-width: 572px) {
  h2 {
    font-size: 20px;
  }

  #Second select,
  h4,
  h5 {
    font-size: 13px;
  }

  .button {
    width: 40%;
  }

  .card1 {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .information {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .card2 h4 {
    font-size: 10px;
  }
}

@media (max-width: 305px) {
  h2 {
    font-size: 15px;
  }

  #Second select,
  h4,
  h5 {
    font-size: 11px;
  }

  .card1 h6 {
    font-size: 10px;
  }

  .card1 img {
    width: 50px;
  }
}
</style>
