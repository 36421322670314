import { render, staticRenderFns } from "./CardStyle4.vue?vue&type=template&id=a86efe2c&scoped=true&"
import script from "./CardStyle4.vue?vue&type=script&lang=js&"
export * from "./CardStyle4.vue?vue&type=script&lang=js&"
import style0 from "./CardStyle4.vue?vue&type=style&index=0&id=a86efe2c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a86efe2c",
  null
  
)

export default component.exports