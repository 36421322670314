<template>

<tr>
  <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

<td> {{ DBitems.product.name }} <span class="product-qty">x {{ item.quantity }}</span>

                                        <div v-if="DBitems.final_options.length != 0" style="height: 30px; overflow-y: auto;border:1px solid var(--primary-color)">
            <div v-if="DBitems.final_options.length != 0">
              <p v-for="(item, index) in DBitems.final_options" :key="index">
                {{ item.option }} : {{ item.value }}
              </p>
            </div>
            <div v-if="DBitems.checkbox_options.length != 0">
              <p v-for="(item, index) in DBitems.checkbox_options" :key="index">
                {{ item }}
              </p>
            </div>
          </div>


                                        
                                        </td>
                                    <td v-if="item.offer_price != 0">
                                        {{ item.offer_price.toFixed(2) }} {{ unit }}
                                    </td>
                                    <td v-else>
                                        {{ item.total_price.toFixed(2) }} {{ unit }}
                                    </td>
                                </tr>
    </template>
    <script>
export default {
  props: {
    itemId: String
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      item: null,
      show: false,
      unit: this.$store.state.unit,
      langChange:""
    };
  },
  created() {
    this.loading = true;
 this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
   
    deleteItem() {
      //this.loading = true;
      this.$cookies.remove(this.itemId);
      console.log("remove" + this.$cookies.keys());
      this.$store.state.cartCounter = this.$store.state.cartCounter - 1;
      this.$destroy();
      this.$parent.updateProducts();
      this.$el.parentNode.removeChild(this.$el);
    },
    updatedata()
    {
      this.loading = true;
 this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }

    this.$store.state.langchange=false;
  }
  },
  updated() {
    this.langChange=this.$i18n.locale;
  },
  watch:
  {
    langChange()
    {
      this.loading = true;
 this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
  },}

   
};
</script>