var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog_post blog_style2 box_shadow1"},[_c('div',{staticClass:"blog_img"},[_c('router-link',{attrs:{"to":{
  name: 'section',
  params: { storeName: _vm.$store.storeName, id: _vm.sectionid  , name:_vm.name},
}}},[_c('img',{attrs:{"src":_vm.image,"alt":"blog_small_img1"}})])],1),_c('div',{staticClass:"blog_content bg-white"},[_c('div',{staticClass:"blog_text"},[_c('h5',{staticClass:"blog_title"},[_c('router-link',{attrs:{"to":{
                               name: 'section',
                                params: { storeName: _vm.$store.storeName, id: _vm.sectionid  , name:_vm.name},
                                   }}},[_vm._v(" "+_vm._s(_vm.name))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }