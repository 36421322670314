<template>
  <section>
<!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{ $t('CreateAccount') }}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item"><router-link to="/">{{ $t('home') }}</router-link></li>
                    <li class="breadcrumb-item active">{{ $t('CreateAccount') }}</li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->

  <!-- START LOGIN SECTION -->
<div class="login_register_wrap section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-md-10">
                <div class="login_wrap">
            		<div class="padding_eight_all bg-white">
                        <div class="heading_s1">
                            <h3>{{ $t('CreateAccount') }}</h3>
                        </div>
                        <div
              v-if="errorMessage != ''"
              style="
                text-align: center;
                padding: 5px;
                background-color: #fff;
                display: inline;
                margin: 0 10px;
              "
            >
              <span style="margin: 0; color: red; display: inline">{{
                errorMessage
              }}</span>
            </div>
                        <form @submit.prevent="submitInfo" @keydown="form.onKeydown($event)">
                            <div class="form-group">
                                <input 
                               
                                  class="form-control"
                                  type="text"
                                  name="name"
                                  autocomplete="false"
                                 :placeholder="$t('EnterName')"
                                   v-model="form.name"
                                  required
                                    maxlength="255"
                                    minlength="3"
                                    id="name"
                                  
                                   >
                            </div>
                            <div class="form-group" v-if="this.$store.state.auth == 1">
                                <input 
                                 class="form-control" 
                                 type="email"
                                 name="email"
                                autocomplete="false"
                                v-model="form.email"
                               placeholder="example@gmail.com"
                               required
                                 >
                            </div>
                            
                            <div v-else class="form-group" id="userNumber">
                                <input 
                                class="form-control" 
                                id="phone"
                                type="text"
                               name="phone"
                               autocomplete=""
                              v-model="form.phone_basic"
                              :placeholder="$t('EnterPhone')"
                               required
                              :minlength=$store.state.country_length
                              :maxlength=$store.state.country_length
                              style="text-align: center;"
                                >
                                <span class="flag">  <h2>{{ $store.state.country_code }}</h2>
                               <img :src="this.$store.state.country_flag" >
                               </span>
                            </div>
                            <div class="form-group">
                                <input 
                                class="form-control" 
                                id="password"
                                type="password"
                                name="name"
                                autocomplete="new-password"
                                v-model="form.password"
                                :placeholder="$t('EnterPass')"
                                required
                                minlength="8"
                                
                                >
                            </div>
                            <div class="form-group">
                                <input 
                                class="form-control"
                                id="passwordconfirm"
                                type="password"
                               autocomplete="new-password"
                               name="name"
                              :placeholder="$t('EnterPass')"
                               required
                                v-model="confirmPassword"
                               minlength="8"
                                >
                            </div>
                            <div class="login_footer form-group">
                                <div class="chek-form">
                                    <div class="custome-checkbox">
                                        <input class="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox2" value="">
                                        <label class="form-check-label" for="exampleCheckbox2"><span>{{$t('I agree to terms Policy')}}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="submit" :disabled="form.busy" 
                                class="btn btn-fill-out btn-block" name="register"
                                >
                                     {{ $t("CreateAccount") }}
                                </button>
                            </div>
                        </form>
                        <div class="different_login">
                            <span> {{ $t('or') }}</span>
                        </div>
                       
                        <div class="form-note text-center">{{ $t('Already have an account?') }} 
                          <router-link to="/login">{{$t('logIn')}}</router-link></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END LOGIN SECTION -->
    </section>
</template>
<script>
// import testImage from "../../../public/assets/image/icons/defult.svg";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
    VuePhoneNumberInput,
  },
  props: {
    phone: String,
    email: String,
  },
  data: function () {
    return {
      isValid: true,
      DBitems: null,
      confirmPassword: null,
      errorMessage: "",
      partphone: null,
      form: new Form({
        name: null,
        /*email: null,*/
        password: null,
        phone: null,
        phone_basic:null,
        email: null,
        fsm_token: this.$store.state.fsm_token,
      }),
    };
  },
  created() {
    if (this.phone != null) {
      this.form.phone = this.phone;
      this.partphone=true;
      this.isValid = true;
    } else if (this.email != null) {
      this.form.email = this.email;
    }
    //console.log("hiiiiiiiiii" +this.form.phone    +   "   "  + this.form.email)
  },
  methods: {
    async submitInfo() {
      if (this.isValid) {
        if (this.confirmPassword == this.form.password) {
          console.log(this.form.phone)
          if(this.form.phone==null)
          {
            this.form.phone=this.$store.state.country_code+this.form.phone_basic
          }
          console.log(this.form.phone)
          const response = await this.form
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",

              {
                form: this.form,
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              if (response.data.code == "1") {
                //this.DBitems = response.data.data.token; //22|JaYzuFIY4eyIXjIh7haHEGxGS8xowdbnkElztmHh//44444444
                //this.$localStorage.set("userToken", response.data.data.token);
                //this.$localStorage.start();
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                //this.$localStorage.set("userToken", response.data.data.token);
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    //this.$localStorage.id() +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
               /* this.$router.push({
                  name: "home1",
                  params: { storeName: this.$store.storeName },
                });
                window.location.reload();*/
                window.location.href=  '/' ;
              } else if (response.data.code == "-1") {
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
            });
        } else {
          if (this.$i18n.locale == "en") {
            this.errorMessage = "the password not matching";
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          } else {
            this.errorMessage = "كلمتا السر غير متطابقتين";
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessage = "your number isn't valid";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        } else {
          this.errorMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        }
      }
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
       
      }
      
    },
  },
};
</script>