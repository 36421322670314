var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.$store.state.langchange)?_c('div',[_vm._v(_vm._s(_vm.updatedata()))]):_vm._e(),(!_vm.loading)?_c('section',{attrs:{"id":"Section","data-aos":"fade-up","data-aos-duration":"2000"}},[_c('div',{staticClass:"container mt-3"},[_c('div',{staticClass:"row sectionSlider",staticStyle:{"justify-content":"center"}},_vm._l((_vm.DBitems.categories.category),function(item,index){return _c('div',{key:index,staticClass:"col-lg-2 col-md-3 col-sm-4 col-6 mb-2"},[_c('button',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({
                  name: 'section',
                   params: {
                     storeName: _vm.$store.storeName ,
                     id: _vm.s_id ,
                     name: _vm.s_name,
                     category_id_selected:item.id }
                 });}}},[_c('img',{staticStyle:{"width":"150px","height":"150px","object-fit":"cover","border-radius":"50%","transition":"500ms"},attrs:{"src":item.icon}}),_c('h5',[_vm._v(_vm._s(item.name))])])])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }