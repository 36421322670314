<template>
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" style="padding-bottom: 0">
      <div>
        <!--style="max-width: 1180px">-->
        <div class="row">
          <div dir="ltr" class="col-lg-12" v-if="!loadingSlider">
            <div class="heroCarousel">
              <carousel
                v-if="sliders.length != 0"
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :paginationEnabled="false"
              >
                <slide v-for="(item, idx) in sliders" :key="idx">
                  <img :src="item.image" @click="sliderLink(item.value)" />
                  <div
                    style="
                      position: absolute;
                      top: 30%;
                      text-align: center;
                      width: 50%;
                      left: 25%;
                      right: 25%;
                    "
                  >
                    <h2>{{ item.title }}</h2>
                    <p>
                      نحن نصدر أفضل أنواع لحوم البقر ونبيعها ، ويتم الحصول عليها
                      بأكبر قدر من العناية من المزارع.
                    </p>
                    <div :dir="$i18n.locale == 'ar'? 'rtl' : 'ltr'"
                      class="mobile-inactive"
                      style="width: 100%; max-width: 800px;margin: auto;"
                    >
                      <input
                        id="search"
                        type="search"
                        autocomplete="false"
                        onblur="this.setAttribute('readonly', 'readonly') , word=''"
                        onfocus="this.removeAttribute('readonly');"
                        readonly
                        class="form-control rounded"
                        :placeholder="$t('search')"
                        :aria-label="$t('search')"
                        aria-describedby="search-addon"
                        style="
                          min-width: 500px;
                          margin-top: 25px;
                          border-radius: 5px !important;
                          height: 55px;
                          background-color: #fff;
                          border: 1px solid #e5e7eb;
                        "
                        v-model="word"
                        @keyup.enter="searchRoute"
                      />
                      <i class="bi bi-search" @click="searchRoute"></i>
                    </div>
                  </div>
                </slide>
              </carousel>
              <div v-else>
                <img src="../../../public/assets/image/slide1.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End hero -->

    <!-- ======= Second Section ======= -->
    <section id="Second">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-center">
          <!--<h2>{{ $t("sections") }}</h2>-->
          <router-link
            :to="{
              name: 'sections',
              params: { storeName: this.$store.storeName },
            }"
          >
            <h2>{{ $t("sections") }}</h2>
            <!--<p>{{ $t("viewAll") }}</p>-->
          </router-link>
        </div>
        <p class="text-brand-muted text-center">
          Here order your favorite foods from different categories
        </p>

        <slider v-if="!loadingSections" :items="sections"></slider>
      </div>
    </section>
    <!-- End Second -->

    <!-- ======= Third Section ======= -->
    <div v-if="!loadingOffers">
      <section id="Third">
        <!--v-if="offers.length!=0" -->
        <div class="container">
          <div class="section-title d-flex flex-row justify-content-center">
            <!--<h2>{{ $t("offers") }}</h2>-->
            <router-link
              :to="{
                name: 'offers',
                params: { storeName: this.$store.storeName },
              }"
            >
              <!--<p>{{ $t("viewAll") }}</p>-->
              <h2>{{ $t("offers") }}</h2>
            </router-link>
          </div>
          <p class="text-brand-muted text-center">
            We provide best quality & fresh grocery items near your location
          </p>
          <div style="margin-top: -50px">
            <slider-offers :items="offers"></slider-offers>
          </div>
        </div>
      </section>
    </div>
    <!-- End Third -->

    <!-- ======= Fourth Section ======= -->
    <section id="Fourth">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-center">
          <!--<h2>{{ $t("bestSeller") }}</h2>-->
          <router-link
            :to="{
              name: 'bestSeller',
              params: { storeName: this.$store.storeName },
            }"
          >
            <!--<p>{{ $t("viewAll") }}</p>-->
            <h2>{{ $t("bestSeller") }}</h2>
          </router-link>
        </div>
        <p class="text-brand-muted text-center">
          We provide best quality & fresh grocery items near your location
        </p>

        <div class="row mt-2" v-if="!loadingProducts">
          <div
            class="col2 col-lg-3 col-md-4 col-6 mb-5"
            v-for="(item, index) in this.products"
            :key="index"
          >
            <card3
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
            ></card3>
          </div>
        </div>
      </div>
    </section>
    <!-- End Fourth -->

    <!-- ======= Fifth Section ======= -->
    <div v-if="!loadingBrands">
      <section v-if="brands != null" id="Third" style="background-color: #fffs">
        <div class="container">
          <div class="section-title d-flex flex-row justify-content-center">
            <router-link
              :to="{
                name: 'brands',
                params: { storeName: this.$store.storeName },
              }"
            >
              <h2>{{ $t("brands") }}</h2>
              <!--<p>{{ $t("viewAll") }}</p>-->
            </router-link>
          </div>
          <p class="text-brand-muted text-center">
            We have categories the best quality grocery items
          </p>

          <slider-brands :items="brands"></slider-brands>
        </div>
      </section>
    </div>
    <!-- End Fifth -->
  </div>
</template>
<script>
import card1 from "../components/CardStyle1.vue";
import card2 from "../components/CardStyle2.vue";
import card3 from "../components/CardStyle3.vue";
import VueHorizontalList from "vue-horizontal-list";
import { Carousel, Slide } from "vue-carousel";
import { off } from "process";
import Slider from "../components/slider.vue";
import SliderOffers from "../components/sliderOffers.vue";
import sliderBrands from "../components/sliderBrands.vue";

export default {
  name: "CarouselPage",
  components: {
    card1,
    card2,
    card3,
    VueHorizontalList,
    Carousel,
    Slide,
    Slider,
    SliderOffers,
    sliderBrands,
  },
  data() {
    return {
      DBitems: null,
      sliders: null,
      sections: null,
      offers: null,
      brands: null,
      products: null,
      loading: false,
      loadingSlider: false,
      loadingSections: false,
      loadingOffers: false,
      loadingBrands: false,
      loadingProducts: false,
      word: "",
      scrollAmount: 0,
      scrollAmount1: 0,

      images: [
        {
          src: "https://mdbootstrap.com/img/Photos/Slides/img%20(68).webp",
          src: "https://mdbootstrap.com/img/Photos/Slides/img%20(6).webp",
        },
      ],
      /*options: {
        responsive: [
          { end: 310, size: 1 },
          { start: 310, end: 576, size: 2 },
          { start: 576, end: 768, size: 4 },
          { start: 768, end: 992, size: 5 },
          { start: 992, end: 1150, size: 7 },
          { size: 8 },
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 24,
        },
        position: {
          start: 2,
        },
        autoplay: { play: false, repeat: true, speed: 2500 },
      },
      options1: {
        responsive: [
          { end: 400, size: 1 },
          { start: 400, end: 768, size: 2 },
          { start: 768, end: 1102, size: 3 },
          { size: 5 },
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 24,
        },
        position: {
          start: 2,
        },
        autoplay: { play: false, repeat: true, speed: 2500 },
      },*/
    };
  },
  created() {
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
    //////////////////////sliders//////////////////////
    this.loadingSlider = true;
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.sliders = response.data.data.sliders))
      .then(() => (this.loadingSlider = false));

    //////////////////////sections//////////////////////
    this.loadingSections = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sections = response.data.data.sections;
        /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
      })
      .then(() => (this.loadingSections = false));
    //////////////////////offers//////////////////////
    this.loadingOffers = true;
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/offers", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => {
        this.offers = response.data.data.offers;
        /*if (this.offers.size > 5) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.offers = this.offers.slice().reverse();
          }
        }*/
      })
      .then(() => (this.loadingOffers = false));
    //////////////////////Brands//////////////////////
    this.loadingBrands = true;
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/brands", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => {
        if (response.data.data != null) {
          this.brands = response.data.data.brands;
        }
      })
      .then(() => (this.loadingBrands = false));

    //////////////////////products//////////////////////
    this.loadingProducts = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/products",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => (this.products = response.data.data.products))
      .then(() => (this.loadingProducts = false));
    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////
    //console.log("from home : " + this.$localStorage.id());
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    searchRoute() {
      if (this.$route.path.includes("/" + this.$store.storeName + "/Search")) {
        console.log(this.word + "hiiiiiii");
        this.$router.push({ name: "search", params: { word: this.word } });
      } else {
        this.$router.push({
          name: "search",
          params: { storeName: this.$store.storeName, word: this.word },
        });
      }
    },
    updatedata() {
      //console.log("hooooooooooooooo");
      /*this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));*/
      //////////////////////sliders//////////////////////
      this.loadingSlider = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sliders"
        )
        .then((response) => (this.sliders = response.data.data.sliders))
        .then(() => (this.loadingSlider = false));

      //////////////////////sections//////////////////////
      this.loadingSections = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sections",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.sections = response.data.data.sections;
          /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loadingSections = false));
      //////////////////////offers//////////////////////
      this.loadingOffers = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/offers",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.offers = response.data.data.offers;
          /*if (this.offers.size > 5) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.offers = this.offers.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loadingOffers = false));
      //////////////////////Brands//////////////////////
      this.loadingBrands = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/brands",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          if (response.data.data != null) {
            this.brands = response.data.data.brands;
          }
        })
        .then(() => (this.loadingBrands = false));
      //////////////////////products//////////////////////
      this.loadingProducts = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/products",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => (this.products = response.data.data.products))
        .then(() => (this.loadingProducts = false));

      this.$store.state.langchange = false;
    },
    sliderLink(slidLink) {
      window.location.href = slidLink;
    },
    /* scrollLeft: function() {
      const menu = this.$refs.menu;
      if (this.$i18n.locale == "ar") {
        //if (this.scrollAmount > -(this.sections.length * 100) + 300) {
        if (this.scrollAmount >= -(this.sections.length * 100)) {
          menu.scrollTo({
            left: (this.scrollAmount -= 300),
            behavior: "smooth"
          });
        }
        console.log(this.scrollAmount);
      } else {
        if (this.scrollAmount <= this.sections.length * 100) {
          menu.scrollTo({
            left: (this.scrollAmount += 300),
            behavior: "smooth"
          });
          console.log(this.scrollAmount);
        }
      }
    },
    scrollRight: function() {
      const menu = this.$refs.menu;
      if (this.$i18n.locale == "ar") {
        if (this.scrollAmount < 0) {
          menu.scrollTo({
            left: (this.scrollAmount += 300),
            behavior: "smooth"
          });
        }
        console.log(this.scrollAmount);
      } else {
        if (this.scrollAmount > 0) {
          menu.scrollTo({
            left: (this.scrollAmount -= 300),
            behavior: "smooth"
          });
        }
        console.log("hii" + this.scrollAmount);
      }
    },*/
    /*scrollLeft1: function () {
      const menu = this.$refs.menu1;
      if (this.$i18n.locale == "ar") {
        if (this.scrollAmount1 > -(this.offers.length * 200)) {
          menu.scrollTo({
            left: (this.scrollAmount1 -= 600),
            behavior: "smooth",
          });
        }
        console.log(this.scrollAmount1);
      } else {
        if (this.scrollAmount1 < this.offers.length * 200) {
          menu.scrollTo({
            left: (this.scrollAmount1 += 600),
            behavior: "smooth",
          });
          console.log(this.scrollAmount1);
        }
      }
    },
    scrollRight1: function () {
      const menu = this.$refs.menu1;
      if (this.$i18n.locale == "ar") {
        if (this.scrollAmount1 < 0) {
          menu.scrollTo({
            left: (this.scrollAmount1 += 600),
            behavior: "smooth",
          });
        }
        console.log(this.scrollAmount1);
      } else {
        if (this.scrollAmount1 > 0) {
          menu.scrollTo({
            left: (this.scrollAmount1 -= 600),
            behavior: "smooth",
          });
        }
        console.log("hii" + this.scrollAmount1);
      }
    },*/
  },
};
</script>
<style scoped>
.menu-wrapper {
  position: relative;
  /*max-width: 310px;height: 100px;*/
  margin: 1em auto;
  border: 0;
  background: transparent;
  overflow-x: hidden;
  overflow-y: hidden;
}

.menu-wrapper .menu {
  /*height: 120px;*/
  /*box-sizing: border-box;*/
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}

.menu-wrapper .item {
  display: inline-block;
  /*width: 100%;*/
  height: 100%;
  /* outline: 1px dotted gray;*/
  padding: 1em;
  box-sizing: border-box;
}

.menu-wrapper .paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  background-color: transparent;
  border: 0;
}
.menu-wrapper .left-paddle {
  left: 0;
}
.menu-wrapper .right-paddle {
  right: 0;
  transform: rotate(180deg);
}
.menu-wrapper .hidden {
  display: none;
}

.menu-wrapper .print {
  margin: auto;
  max-width: 500px;
}

.menu-wrapper span {
  display: inline-block;
  width: 100px;
}
.paddle img {
  box-shadow: 0 0 7px 0 rgb(134, 134, 134);
  border-radius: 50px;
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  margin-top: 0px;
  padding-top: 80px;
  background-image: url("../../../public/assets/image/background.png");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 80%;
  /*margin-right: 15px !important;
    margin-left: 15px !important;
    overflow: hidden;
    position: relative;
    background: transparent;
    padding: 0;*/
}

#hero img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

#hero .heroCarousel {
  margin-top: 0;
  /* height: 390px;
  width: 100%;
  max-width: 500px;*/
  aspect-ratio: 1200 / 400;
  border-radius: 0;
  padding: 0;
}

.VueCarousel-inner {
  border-radius: 0px !important;
}

.heroText {
  background-color: #ebebeb;
  width: 100%;
  padding: 20px;
}
#hero h2 {
  font-family: "Araboto-Black";
  font-size: 25px;
  text-align: center;
  color: var(--primary-color);
}

#hero h3 {
  font-family: "Araboto-Black";
  font-size: 18px;
  margin-top: 20px;
  color: #000;
  text-align: center;
}

#hero p {
  font-family: "Araboto-Medium";
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  color: var(--secondary-color);
}
#hero .info {
  margin-top: 0 !important;
}
#hero .info p,
#hero .info .number {
  color: #222;
  font-family: "Araboto-Medium";
  font-size: 25px;
}
#hero .info span {
  color: var(--secondary-color);
  font-family: "Araboto-Medium";
  font-size: 16px;
}

#hero img {
  object-fit: cover;
  border-radius: 0;
}

#hero .info .bi {
  font-size: 60px;
  margin: 0 20px;
  color: var(--primary-color);
  /*text-shadow: 0 0px 10px #000000;*/
}

.section-title {
  padding-bottom: 0px;
}
.section-title h2 {
  font-size: 18px;
  font-family: "Araboto-Black";
  color: #000;
}
.section-title h2:hover {
  color: var(--primary-color);
}

section p {
  padding-bottom: 20px;
}

[dir="rtl"] .bi-search {
  margin-right: 95% !important;
  top: -42px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}
[dir="ltr"] .bi-search {
  margin-left: 95% !important;
  top: -42px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}

/*@media (min-width: 2000px) {
  #hero p {
    font-size: 25px !important;
    width: 70% !important;
  }
  .col2 {
    flex: 0 0 auto ;
    width: 16.66666667% !important;
  }
}

@media (min-width: 1400px) {

  #hero {
    margin-top: 120px;
  }

  #hero .heroCarousel {
    margin-top: 25px;
    height: 490px;
    width: 100%;
    border-radius: 25px;
    padding: 0;
  }

  #hero h2 {
    font-size: 60px;
  }

  #hero h3 {
    font-size: 40px;
  }

  #hero p {
    font-size: 20px;
    width: 80%;
  }
  #hero .info p {
    font-size: 34px;
  }
  #hero .info span {
    font-size: 20px;
  }
}*/

/*@media (min-width: 1024px) {
  #hero p {
    width: 90%;
  }
}*/

@media (min-width: 992px) {
  .col-lg-3 {
    width: 20%;
  }
}
@media (max-width: 992px) {
  #hero .carousel-container {
    margin-bottom: 15px;
  }
  #hero h2 {
    font-size: 22px;
  }
  #hero h3 {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  #hero p {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    line-height: 20px !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  #hero .info .bi {
    font-size: 40px;
    margin: 0 10px;
  }
  #hero .info p {
    /* line-height: 10px;*/
    margin-top: 10px;
  }
  #hero .information {
    justify-content: center !important;
  }
  #hero .button {
    font-size: 16px;
  }
  #hero {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 630px) {
  #hero h2 {
    font-size: 21px;
  }
  #hero h3 {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  #hero p {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }
  #hero .info .bi {
    font-size: 30px;
  }
  #hero .button {
    font-size: 14px;
  }
  /* #hero .heroCarousel {
    height: 290px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }*/
}

@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  #hero h3 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  #hero p {
    font-size: 12px !important;
    margin-bottom: 3px !important;
  }
  #hero .button {
    width: 180px;
    font-size: 11px;
  }
  .section-title p {
    font-size: 16px;
  }
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 400px) {
  #hero h2 {
    font-size: 15px;
  }
  #hero h3 {
    font-size: 10px;
    margin-bottom: 7px !important;
  }
  #hero p,
  #hero .info span {
    font-size: 8px !important;
  }

  #hero .button {
    font-size: 7px;
  }
  /*#hero .heroCarousel {
    height: 200px;
  }*/
}

/*@media (min-width: 1200px){
.container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
}}*/
</style>
