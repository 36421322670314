<template>
    
    <div class="card">

                        	<div class="card-header">
                                <h3>{{ $t('myOrders') }}</h3>
                            </div>
                            <div class="card-body">
                    			<div v-if="this.DBitems.length!=0" class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{{ $t('order') }}</th>
                                                <th>{{$t('creationDate')}}</th>
                                                <th>{{$t('status')}}</th>
                                                <th>{{$t('total')}}</th>
                                                <th>{{$t('Action')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <order_list
                                            
                                            v-for="(item, index) in this.DBitems"
                                            :key="index"
                                            :id="item.id"
                                            :invoice_id="item.invoice_id"
                                            :date="item.date"
                                            :cost="item.total_price"
                                            :state="item.state"
                                            >
                                            </order_list>

                                            
                                            
                                        </tbody>
                                    </table>




                                    <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count>1">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                @change="handlePageChange"
              ></b-pagination>
            </div>
                                </div>


                                <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>


                            </div>


                            

                        </div>

</template>

<script>
import order_list from "../components/order_list.vue";

export default {
    components: { order_list},
  data: function() {
    return {
      DBitems: null,
      DBitems1: null,

      loading: false,
      loading1: false,

      activeTab: false,
      page: 1,
      count: 0,
      pageSize: 6,

      page1: 1,
      count1: 0,
      pageSize1: 6,

      langChange:""
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/current",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.orders;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });

    ///prev
    this.loading1 = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/previous",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems1 = response.data.data.orders;
        this.count1 = response.data.meta.total;
      })
      .then(() => (this.loading1 = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
  },
  methods: {
    updatedata() {
      this.loading = true;
     

      ///orders
      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      this.$store.state.langchange = false;
    },
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    retrieveTutorials1() {
      const params = this.page1;

      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    }
  },
  
  watch:
  {
    langChange()
    {
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/current",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.orders;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
};
</script>