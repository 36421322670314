<template>
  <!--<router-link
    :to="{
      name: 'section2',
      params: { storeName: $store.storeName, id: sectionid  , name:name},
    }"
  >-->
  <div>
    <div class="card" @click="dispalyTabFunction()">
      <div class="d-flex flex-row justify-content-center">
        <div class="image">
          <!-- onclick="openTab('tab1')">-->
          <img :src="image" />
        </div>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <h5>{{ name }}</h5>
      </div>
      <div v-if="active" style=" text-align: center; margin-top: 20px;">
        <i class="bi bi-arrow-down" style=" font-size:20px; font-weight:700"></i>
      </div>
    </div>
  </div>
  <!--</router-link>-->
</template>

<script>
export default {
  props: {
    name: String,
    image: String,
    sectionid: Number,
    active: Boolean
  },
  methods: {
    dispalyTabFunction() {
      this.$parent.$parent.$parent.dispalyTabFunction(this.sectionid);
    }
  }
};
</script>

<style scoped>
.card {
  cursor: pointer;
  width: 130px;
  height: 100%;
  border: 0;
  background: transparent;
}
.card h5 {
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 18px;
}
.image {
  width: 100%;
  height: 130px;
  width: 130px;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: 500ms;
}
.card:hover > div h5 {
  color: var(--primary-color);
  /*transform: scale(1.02);*/
}

.card:hover > div div img {
  border-radius: 15px;
}

@media (min-width: 1200px) {
  .card {
    width: 100%;
    max-width: 180px;
  }
  .image {
    height: 150px;
    width: 150px;
  }
}
/*@media (min-width: 1400px){
  .card {
    width: 200px;
  }
  .card h5 {
  font-size: 30px;
}
  .image{
    height: 200px;
  }
}

@media (min-width: 2000px){
  .card {
    width: 290px;
  }
  .card h5 {
  font-size: 30px;
}
  .image{
    height: 290px;
  }
}*/

@media (max-width: 575px) {
  .card h5 {
    font-size: 16px;
  }
}
</style>
