<template>
  <section id="Second"><!-- v-if="this.$localStorage.get(this.$store.storeName) == null"-->
    <div class="container">
      <h3>{{ $t("logIn") }}</h3>
      <div ><!--v-if=" this.$store.state.auth==1"-->
        <p>{{ $t("inputPhone") }}</p>
        <form @submit.prevent="sendPhone" @keydown="form.onKeydown($event)">
          <div class="input1">
            <VuePhoneNumberInput
              dir="ltr"
              required
              fetch-country
              show-code-on-list
              no-example
              @update="sendPhone1($event)"
              v-model="partphone"
            />
          </div>
          <div
            v-if="validateMessage != ''"
            style="text-align: center; margin-top: 10px"
          >
            <span style="color: red; margin: 0">{{ validateMessage }}*</span>
          </div>
          <button type="submit" :disabled="form.busy" class="button">
            {{ $t("continue") }}
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";

export default {
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      isValid: false,
      partphone: null,
      validateMessage: "",
      form: new Form({
        phone: null,
      })
    };
  },
   mounted(){
    try{
     if (this.$i18n.locale == "ar") {
      document
        .getElementsByClassName("input-tel__input")[0]
        .setAttribute("placeholder", "رقم الهاتف");
      document.getElementsByClassName("country-selector__label")[0].innerHTML =
        "رمز الدولة";
      document.getElementsByClassName("input-tel__label")[0].innerHTML =
        "* رقم الهاتف";
      /*document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');
  document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');*/
    }
    }catch(e){

    }
  },
  methods: {
    async sendPhone() {
      if (this.isValid == true) {
        await this.form
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/app/genret_code",
            {
              phone: this.form.phone,
              //country: this.form.country,
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            if (response.data.code == "-1") {
              console.log(response.data.code);
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.$router.push({
                name: "activateCode",
                params: {
                  storeName: this.$store.storeName,
                  phone: this.form.phone,
                },
              });
              //console.log( response.data.code);
              //console.log( response.data.message);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your number isn't valid";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.form.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#Second {
  margin-top: 100px;
  min-height: 700px;
}

input {
  border: 0;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  width: 100%;
  height: 32px;
  /*margin-top: 1%;*/
  border-radius: 10px;
  text-align: center;
  color: var(--secondary-color);
  padding: 25px 0 30px 0;
}
h3 {
  font-size: 20px;
  font-family: "Araboto-Bold";
  color: var(--primary-color);
  text-decoration: underline;
  text-underline-offset: 10px;
}
h4 {
  font-size: 18px;
  font-family: "Araboto-Medium";
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
}
p {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 15px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  text-align: center;
}
.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 30%;
  margin-left: 35%;
  margin-right: 35%;
  padding: 10px 0 15px;
  text-align: center;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  margin-top: 60px;
}

.button:hover {
  background-color: #fff;
  color: #000;
}
.input1 {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
}
/*@media (min-width: 1400px){
.container {
    max-width: 1200px !important;
}
}*/
@media (max-width: 992px) {
  h4 {
    font-size: 15px;
  }
  .card-img-actions .preview-1 {
    margin-top: 30px;
    width: 120px !important;
    height: 120px !important;
  }
  .card-img-actions {
    margin-top: 40px;
  }
  .input1 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
  .button {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (max-width: 772px) {
  .input1 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
  }
  .button {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
  p {
    font-size: 12px;
  }
}
</style>
