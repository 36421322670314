import { render, staticRenderFns } from "./Offers.vue?vue&type=template&id=ec672678&scoped=true&"
import script from "./Offers.vue?vue&type=script&lang=js&"
export * from "./Offers.vue?vue&type=script&lang=js&"
import style0 from "./Offers.vue?vue&type=style&index=0&id=ec672678&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec672678",
  null
  
)

export default component.exports