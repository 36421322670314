import { render, staticRenderFns } from "./Loginphone2.vue?vue&type=template&id=a48e756a&scoped=true&v-if=this.%24localStorage.get(this.%24store.storeName)%20%3D%3D%20null&"
import script from "./Loginphone2.vue?vue&type=script&lang=js&"
export * from "./Loginphone2.vue?vue&type=script&lang=js&"
import style0 from "./Loginphone2.vue?vue&type=style&index=0&id=a48e756a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a48e756a",
  null
  
)

export default component.exports