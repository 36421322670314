<template>
    <section v-if="this.$localStorage.get(this.$store.storeName) != null">

      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
            <!-- START SECTION BREADCRUMB -->
    <div class="breadcrumb_section bg_gray page-title-mini">
        <div class="container"><!-- STRART CONTAINER -->
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="page-title">
                        <h1>{{$t('My Account')}}</h1>
                    </div>
                </div>
                <div class="col-md-6">
                    <ol class="breadcrumb justify-content-md-end">
                        <li class="breadcrumb-item"><router-link to="/">{{ $t('home') }}</router-link></li>
                        <li class="breadcrumb-item active">{{$t('My Account')}}</li>
                    </ol>
                </div>
            </div>
        </div><!-- END CONTAINER-->
    </div>
    <!-- END SECTION BREADCRUMB -->
    
    
    <!-- START SECTION SHOP -->
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-4">
                    <div class="dashboard_menu">
                        <ul class="nav nav-tabs flex-column" role="tablist">
                
                <li class="nav-item">
                  <router-link class="nav-link" id="orders-tab" data-toggle="tab" 
                  to="/myOrders" role="tab"
                   aria-controls="orders" aria-selected="false">
                   <i class="ti-shopping-cart-full"></i>{{$t('myOrders')}}</router-link>
                </li>
                <li class="nav-item">
                  <router-link 
                  class="nav-link active" id="address-tab" data-toggle="tab" 
                  to="/myAddresses" role="tab" 
                  aria-controls="address" aria-selected="true">
                  <i class="ti-location-pin"></i>{{$t('myAddresses')}}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" 
                  id="account-detail-tab active" data-toggle="tab" 
                  to="/profile" role="tab" 
                  aria-controls="account-detail" aria-selected="true">
                  <i class="ti-id-badge"></i>{{$t('Account Details')}}</router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" 
                  style="cursor:pointer"
                   @click="logOut">
                   <i class="ti-lock"></i>{{ $t('logOut') }}</a>
                </li>
              </ul>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="tab-content dashboard_content ">
                        <div class="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">
                            <div class="row">
                                <div v-if="DBitems.length!=0"
                                        v-for="(item, index) in DBitems" :key="index" class="col-lg-6">
                                    <div class="card mb-3 mb-lg-0">
                                        <div class="card-header">
                                            <h3>{{ $t('myAddresses') }}</h3>
                                        </div>
                                        <div 
                                        class="card-body">
                                            <address>
                                            {{ item.title }}<br>
                                            {{ item.description }}<br>
                                           
                                             </address>
                                            
                                            <a @click="getAddress(item.id)"  class="btn btn-fill-out">{{ $t('edit') }}</a>
                                        </div>

                                       
                 
                                    </div>
                                    
                                </div>
                                <div v-else>
                                        <div class="empty text-center center-text">
                 
                                     <img src="/pet_shop/assets/images/empty.png"/>
                                                         <br>
                                                {{ $t('empty') }}
                                                    </div>
                                               </div>

                             
                                
                            </div>
<br>
                            <div>

<button
class="btn btn-fill-out"
@click="
addAddress = true;
address = null;
"
>
{{ $t("AddNewAddress") }}
</button>
<transition
name="bounce"
enter-active-class="bounceInLeft"
leave-active-class="bounceOutRight"
>
<div v-if="this.address != null" >
<view_address :address="address"></view_address>
</div>
</transition>
<transition
name="bounce1"
enter-active-class="bounceInLeft"
leave-active-class="bounceOutRight"
>
<div v-if="this.addAddress == true" >
<add-address></add-address>
</div>
</transition>
</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->
    
    <Transition>
  <div v-if="show2" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmlogout") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="logOut()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show2 = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
        </section>
        </template>
        <script>
import AddAddress from "../components/AddAddress.vue";
import view_address from "../components/ViewAddress.vue";
import { HalfCircleSpinner ,HollowDotsSpinner} from "epic-spinners";

export default {
  components: {  AddAddress, HollowDotsSpinner, HalfCircleSpinner,view_address },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      address: null,
      addAddress: false,
      show2: false,
      langChange:''
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.addresses.addresses;
        // console.log(this.DBitems);
      })
      .then((this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
    // console.log("from add : "+this.$localStorage.id())
    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    logOut() {
      this.loading = true;
      this.axios.get(
        this.$store.state.base_api_url + this.$store.storeName + "/logout",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      );
      //console.group("hiiiiii")
      console.log(
        "logout:" +
          //this.$localStorage.id() +
          this.$localStorage.get(this.$store.storeName)
      );
      //this.$localStorage.remove("userToken");
      this.show2=false;
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        console.log('hhhhhhhhhhhhhhh111');
        if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
          this.$cookies.remove(console.log(AllCookies[i]));
        
        }
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

      }
      console.log(AllCookies);
      /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
      this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
      }*/
      this.$localStorage.remove(this.$store.storeName);
      window.location.reload();
      this.loading = false;
    },
    getAddress(id) {
      this.loading = true;
      this.addAddress = false;
      this.address = null;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/" +
            id +
            "/edit",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.address = response.data.data;
          //console.log(this.address.address.title);
        })
        .then((this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
    },
    updatAddress() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.addresses.addresses;
          this.address = null;
          this.addAddress = true;
          //console.log(this.addAddress)
          // console.log(this.DBitems);
        })
        .then((this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
    }
  },
  watch:
  {
    langChange()
    {
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.addresses.addresses;
        // console.log(this.DBitems);
      })
      .then((this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
  },


};
</script>
<style >
.vs__dropdown-toggle{
  border:none !important;
  background:transparent !important;
}
</style>