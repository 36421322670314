<template>
  
	<div class="home_section container">

		<div class="row justify-content-center">
            
<!-- image -->
<div class="image_section_container row no-gutters">
<img  class="section_image" :src="image" />
</div>
<!-- end image -->

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="heading_s1 text-center">
              <router-link 
                            :to="{
                              name: 'section',
                              params: { storeName: $store.storeName,
                               id: this.id  ,
                               name:this.name},
                                  }"
                                  >
                <h2 style="cursor:pointer">{{ name }}</h2>

                </router-link>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="tab-style1">
                <ul class="nav nav-tabs justify-content-center" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="arrival-tab" 
                        data-toggle="tab" 
                        href="#arrival" 
                        role="tab" 
                        @click="change_product('0')"
                        aria-controls="arrival" 
                        aria-selected="true">{{ $t('all') }}</a>
                    </li>
                    <li class="nav-item" 
                    v-for="(item, index) in this.categories"
                    >
                        <a class="nav-link " id="arrival-tab" 
                        data-toggle="tab" 
                        href="#arrival" 
                        role="tab" 
                        @click="change_product(item.id)"
                        aria-controls="arrival" 
                        aria-selected="true">{{item.label}}</a>
                    </li>
                    
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-content">
                	<div class="tab-pane fade show active products_list_slick" >
                                  	
                                      <VueSlickCarousel 
                                          ref="slick"
                                          v-if="new_products.length !=0 && this.loading==false"
                                           :options="settings"
                                           @reInit="handlereInit"
                                      class="product_slider carousel_slider owl-carousel owl-theme nav_style1"
                                      
                                       >
                          <div  v-for="(item, index) in this.new_products"
                      :key="index" 
                      class="one_product_list"
                      >
                    <product_list
                    :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                        >
                            </product_list>
                          
                    </div>
                  </VueSlickCarousel>
                  <div v-else-if="this.loading">
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                  <br>
                  
                  </div>
                  </div>
                  <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>
                    
                  <a class="carousel-control-prev product_next" role="button" data-slide="prev" @click="prev_product()"><i class="ion-chevron-left"></i></a>
                  <a class="carousel-control-next product_prev"  role="button" data-slide="next" @click="next_product()"><i class="ion-chevron-right"></i></a>

                    </div>
                
                </div>
                
            </div>
        </div>
    </div> 
</div>
</div>
<div class="heading_s1 text-center all_product_btn">
              <router-link 
                            :to="{
                              name: 'section',
                              params: { storeName: $store.storeName,
                               id: this.id  ,
                               name:this.name},
                                  }"
                                  >
                <h6   style="cursor:pointer">{{$t('all product') }}</h6>

                </router-link>
            </div>
</div>
</template>
<script>
import product_list from "../components/product_list2.vue";
import VueSlickCarousel from 'vue-slick'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: { product_list ,VueSlickCarousel},
  props: {
    name: String,
    image:String,
    id: Number,
    categories:Array,
   

  },
  data: function () {
   
    return {
      loading:false,
      langChange:'',
    new_products :[],
    settings:
        {
  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ],
  // "centerMode": true,
  "centerPadding": "20px",
  "centerMargin": "20px",
  //  "focusOnSelect": false,
},
    };
  },
  created()
  {
    this.new_products=[];
      this.type=this.id;

      this.loading = true;
     
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products/section/"+this.type+"/category/0",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.new_products = response.data.data;
      })
      .then(() => (this.loading = false));
  },
  methods: {
    next_product() {
            this.$refs.slick.next();
        },

        prev_product() {
            this.$refs.slick.prev();
        },
        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
    change_product(new_type) {
      this.new_products=[];
      this.type=this.id;

      this.loading = true;
     
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products/section/"+this.type+"/category/"+new_type,
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.new_products = response.data.data;
      })
      .then(() => (this.loading = false));
     
    },
    popup(id)
    {
      this.$store.state.productid_popup=id
      if(this.$store.state.show_product==false)
      this.$store.state.show_product=true;
    else
    this.$store.state.show_product=false;
    },

    updatedata()
    {
      this.products=[];
      console.log('ffffffffffffffffffffffffffffffffffff')
      change_product(0);
      this.$store.state.langchange=false;
    }
  },
  watch:{
    langChange() {
      change_product(0);
     
  },
  updated() {
    this.langChange=this.$i18n.locale
    this.new_products=[];
    console.log('##############')

  },
}
}
</script>
<style>
.product_next,
.product_prev
{
  background-color: transparent !important;
    font-size: 18px;
    color: black !important;
    height: 50px;
    opacity: 1 !important;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    transition: all 0.3s ease-in-out;
}
.product_prev{
    right: -5% !important;
}
.product_next {
    left: -5% !important;
}
.product_slider .slick-slide
{
  margin-right:20px !important;
  
}
@media screen and (min-width: 1200px)
{
.products_list_slick{
  width:1130px !important
}
.one_product_list
{
  width:262.5px !important;
}

}



@media only screen and (min-width: 1200px) {

.section_image
{
object-fit:cover;
min-width:1200px;
width:1200px !important;
aspect-ratio: 3/1;
}

}
@media only screen and (max-width: 1199px) AND (min-width: 1000px) {
.section_image
{
object-fit:cover;
min-width:1000px;
aspect-ratio: 3/1;
width:600px;
}
}

@media only screen and (max-width: 1000px) AND (min-width: 901px) {
.section_image
{
object-fit:cover;
min-width:900px;
aspect-ratio: 3/1;
width:900px;
}
}

@media only screen and (max-width: 900px) AND (min-width: 600px) {
.section_image
{
object-fit:cover;
min-width:600px;
aspect-ratio: 3/1;
width:600px;
}
}


@media only screen and (max-width: 599px) AND (min-width: 300px) {

.section_image
{
object-fit:cover;
min-width:300px;
width:300px;
aspect-ratio: 3/1;
}
}

@media only screen (min-width: 300px) {

.section_image
{
object-fit:cover;
min-width:200px;
width:200px;
aspect-ratio: 3/1;
}
}
.home_section .all_product_btn
{
  font-weight: 700;
    padding-top: 20px !important;
    text-decoration: underline;
}
.home_section .all_product_btn h6
{
  font-weight: 700;
    padding-top: 20px !important;
    color: #f93539;
    text-decoration: underline;
}
.home_section .section.pb_70{
    padding-top: 20px !important;
}

</style>