<template>
    <!-- START SECTION SECTIONS -->
<section>
    <div 
     class="single_section section small_pt pb_70"
    v-for="(item, index) in this.sections"
   >
    <section_list
    :name="item.name"
    :id="item.id"
    :image="item.image"
    :categories="item.categories"
    >

    </section_list>
    </div>
    </section>

<!-- END SECTION SECTIONS -->

</template>

<script>
import section_list from "../components/one_sectionhome.vue";

export default {
  components: { section_list},
  data() {
    return {
        DBitems: null,
      loading: false,
     sections:[
               ],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
      type:'new',
     
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/home/sections",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data;

      })
      .then(() => (this.loading = false));
      console.log('*****************************sections home  ',this.sections);

  },
  watch: {
    langChange() {
      this.sections=[];
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/home/sections",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data;

      })
      .then(() => (this.loading = false));
      console.log('*****************************sections home  ',this.sections);

    },
  },
  updated() {
    this.langChange=this.$i18n.locale
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/home/sections",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data;
       
      })
      .then(() => (this.loading = false));
  },
  methods: {
  

  },
};
</script>
<style>
.single_section.section.pb_70 
  {
    padding-bottom: 0px !important;
    padding-top: 20px !important;
}

</style>