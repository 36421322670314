<template>
  <router-link
    :to="{
      name: 'product',
      params: { storeName: $store.storeName, id: product_id , name:this.product_name},
    }"
  >
    <div class="card" >
      <div class="image">
        <img :src="image" />
        <div class="mask" style="background-color: rgba(0, 0, 0, 0.3)">
          <div class="d-flex flex-row justify-content-center">
            <svg  class="img1" xmlns="http://www.w3.org/2000/svg" width="68.393" height="81.247"><path d="M0 0h68.393v77.835c0 2.407-2.522 3.983-4.686 2.926l-26.95-13.159a3.257 3.257 0 0 0-2.78-.037L4.607 80.95C2.45 81.932 0 80.356 0 77.986V0Z" fill="#3896a0" fill-rule="evenodd" data-name="Vector 14"/></svg>
          </div>
          <div class="text text-center">
            <p style="font-size: 15px">{{$t("OFF")}}</p>
            <p style="font-size: 20px; line-height: 5px">{{ discount }}%</p>
          </div>
          
          <!--<div class="star-container d-flex flex-row justify-content-center">
            <span v-for="n in rate" :key="n">
              <i class="star-fill bi bi-star-fill"></i>
            </span>
            <div>
              <span v-for="j in rate_inverse" :key="j">
                <i class="star bi bi-star-fill"></i>
              </span>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center">
            <img class="img2" src="../../public/assets/image/icons/store.png" />
          </div>-->
        </div>
      </div>
    </div>
    <div class="product-name text-center">
            <p>{{ product_name }}</p>
          </div>
  </router-link>
</template>

<script>
export default {
  props: {
    product_id: Number,
    product_name: String,
    discount: Number,
    image: String,
    rate: Number
    // name: String,
    // image: String
  },
  data: function() {
    return {
      rate_inverse: Number
    };
  },
  created() {
    this.rate_inverse = 5 - this.rate;
  }
};
</script>

<style scoped>
.card {
  width: 207px;
  height: 200px;
  border: 0;
  background: transparent;
  /*color: var(--primary-color);
  font-family: "Araboto-Bold";
  font-size: 22px;*/
}
.card:hover {
  transform: scale(1.02);
}
.image {
  width: 100%;
  height: 100%;
}
.card img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
  border-radius: 15px;
}
.mask .img1 {
  top: 0;
  width: 68px;
  border-radius: 0;
}
.mask .img2 {
  top: 0;
  width: 40px;
  border-radius: 0;
}
.mask .text {
  position: absolute;
  top: 10px;
  width: 100%;
}
.product-name {
  margin-top: 10px;
  line-height: 20px;
  color: var(--secondary-color);
    font-family: "Araboto-Bold";
    font-size: 18px;
  }
.star-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  font-size: 20px;
}
.star {
  color: #fff;
  margin-left: 2px;
  margin-right: 2px;
}
.star-fill {
  color: #ffcc00;
  margin-left: 2px;
  margin-right: 2px;
}

@media (min-width: 1400px){
  .card {
    /*width: 95%;
    max-width: 270px;
    min-width: 250px;*/

    width: 100%;
    max-width: 220px;
  }
}

 svg path{
  fill : var(--primary-color) ;
}
/*
@media (min-width: 1400px){
  .card {
    width: 250px;
  height: 300px;
  }

.mask .img1 {
  top: 0;
  width: 100px;
  border-radius: 0;
}
.mask .img2 {
  top: 0;
  width: 60px;
  border-radius: 0;
}
.mask .text {
  top: 18px;
}
.mask .text p{
  font-size: 20px !important;
}
.product-name {
  font-size: 20px;
  margin-top: 20px;
}
.star-container {
  font-size: 28px;
}

}

@media (min-width: 2000px){
  .card {
    width: 300px;
  height: 370px;
  }

.mask .img1 {
  top: 0;
  width: 128px;
  border-radius: 0;
}
.mask .img2 {
  top: 0;
  width: 80px;
  border-radius: 0;
}
.mask .text {
  top: 20px;
}
.mask .text p{
  font-size: 30px !important;
}
.product-name {
  font-size: 25px;
  margin-top: 30px;
}
.star-container {
  font-size: 30px;
}

}*/
</style>
