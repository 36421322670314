<template>
  <!-- START FOOTER -->
  <footer class="footer_dark">
  <div v-if="this.$store.state.langchange ||this.$store.state.langchange1">{{ updatedata() }}</div>

	<div class="footer_top">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">
                	<div class="widget">
                        <div class="footer_logo">
                            <router-link 
                            
                            :to="{
                               name: 'home',
                              params: { storeName: this.$store.storeName },
                                   }"
                            ><img :alt="this.$store.state.storeName" 
                            :src="DBitems.market.logo_footer"  /></router-link>
                        </div>
                        <p>{{ DBitems.market.name }}
                          {{ DBitems.market.description }}
                          </p>

                        <p style="color:var(--primary-color)">{{ DBitems.market.sub_description }}</p>
                    </div>
                    <div class="widget">
                        <ul class="social_icons social_white">
                            <li v-if="DBitems.social.facebook"><a  target="_blank" :href="DBitems.social.facebook"  >
                              <i class="ion-social-facebook"></i></a></li>
                            
                            <li v-if="DBitems.social.whatsapp"><a  target="_blank" 
                            :href="'https://api.whatsapp.com/send?phone='+DBitems.social.whatsapp+'&text=Hello!'"><i class="ion-social-whatsapp"></i></a></li>


                            <li v-if="DBitems.social.twitter"><a  target="_blank" 
                            :href="DBitems.social.twitter"><i class="ion-social-twitter"></i></a></li>

                            <li v-if="DBitems.social.tiktok" target="_blank"><a  :href="DBitems.social.tiktok"><i class="ion-social-tiktok"></i></a></li>
                            <li><a v-if="DBitems.social.snapchat" target="_blank" :href="DBitems.social.snapchat"><i class="ion-social-snapchat"></i></a></li>

                            <li v-if="DBitems.social.instgram"><a  target="_blank" :href="DBitems.social.instgram">
                            <i class="ion-social-instagram-outline"></i></a></li>
                        </ul>
                    </div>
        		</div>
                <div class="col-lg-2 col-md-3 col-sm-6 footer_column">
                	<div class="widget">
                        <h6 class="widget_title">{{ $t('Useful Links') }}</h6>
                        <ul class="widget_links">
                            <li>
                                
                                <router-link  
                                
                               
                                :to="{
                                  name: 'page',
                                  params: { storeName: this.$store.storeName ,page: 'PrivacyPolice' 
                                  },
                                  }">{{ $t('privacyPolicy') }}</router-link>
                                
                                </li>
                             <li>
                                <router-link  :to="{
                                      name: 'page',
                               params: { storeName: $store.storeName,page: 'Aboutus',  },
                                }">{{ $t('about') }}</router-link>
                                </li>
                            <li><router-link  
                            :to="{
                               name: 'sections',
                              params: { storeName: this.$store.storeName },
                                   }"
                                   
                                   >{{ $t('sections') }}</router-link></li>
                            <li><router-link :to="{
                               name: 'products',
                              params: { storeName: this.$store.storeName },
                                   }">{{ $t('shop') }}</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 footer_column">
                	<div class="widget">
                        <h6 class="widget_title">{{ $t('sections') }}</h6>
                        <ul class="widget_links">
                            <li  v-for="(item, index) in this.DBitems.sections"
                                :key="index">
                              <router-link :to="{
                                                name: 'section',
                                                params: { storeName: $store.storeName, id: item.id  , name:item.label},
                                                 }"
                                                >{{item.label}}</router-link>
                              </li>
                           
                           
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 footer_column">
                	<div class="widget">
                        <h6 class="widget_title">{{ $t('My Account') }}</h6>
                        <ul class="widget_links">
                            <li>
                              <router-link v-if="this.$localStorage.get(this.$store.storeName) == null"
                              
                                
                                :to="{
                               name: 'login',
                              params: { storeName: this.$store.storeName },
                                   }"
                                >{{ $t('profile') }}</router-link>
                              <router-link v-else :to="{
                               name: 'profile',
                              params: { storeName: this.$store.storeName },
                                   }">{{ $t('profile') }}</router-link>

                              </li>
                            <li><router-link 
                              :to="{
                               name: 'shopping_cart',
                              params: { storeName: this.$store.storeName },
                                   }"
                            >{{ $t('shoppingCart') }}</router-link></li>
                            <li>
                              <router-link v-if="this.$localStorage.get(this.$store.storeName) == null"
                                :to="{
                               name: 'login',
                              params: { storeName: this.$store.storeName },
                                   }"
                                >{{ $t('myOrders') }}</router-link>

                              <router-link v-else 
                             
                              :to="{
                               name: 'myOrders',
                              params: { storeName: this.$store.storeName },
                                   }"
                              >{{ $t('myOrders') }} </router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 footer_column">
                	<div class="widget">
                        <h6 class="widget_title">{{ $t('Contact Info') }}</h6>
                        <ul class="contact_info contact_info_light">
                            <li v-if="this.DBitems.market.address">
                                <i class="ti-location-pin"></i>
                                <p>{{ this.DBitems.market.address }}</p>
                            </li>
                            <li v-if="this.DBitems.social.email">
                                <i class="ti-email"></i>
                                <a :href=this.DBitems.social.email>{{ this.DBitems.social.email }}</a>
                            </li>
                            <li v-if="this.DBitems.social.phone">
                                <i class="ti-mobile"></i>
                                <p dir="ltr">{{ this.DBitems.social.phone }}</p>
                            </li>
                            <li v-if="this.DBitems.social.other_phone">
                                <i class="ti-mobile"></i>
                                <p dir="ltr">{{ this.DBitems.social.other_phone }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_footer border-top-tran">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                  
                  <p v-if="DBitems.market.copy_right" class="mb-md-0 text-center text-md-left">{{ $t("all rights are save") }}  {{ this.DBitems.market.name }}</p>
                  <p v-else class="mb-md-0 text-center text-md-left">{{ $t("all rights are save") }}  {{ this.DBitems.market.name }}</p>

                  
                </div>

                <ul class="footer_payment text-center text-lg-right col-md-4">
                        <li><a href="#"><img src="/pet_shop/assets/images/visa.png" alt="visa"></a></li>
                        <li><a href="#"><img src="/pet_shop/assets/images/discover.png" alt="discover"></a></li>
                        <li><a href="#"><img src="/pet_shop/assets/images/master_card.png" alt="master_card"></a></li>
                        <li><a href="#"><img src="/pet_shop/assets/images/paypal.png" alt="paypal"></a></li>
                        <li><a href="#"><img src="/pet_shop/assets/images/amarican_express.png" alt="amarican_express"></a></li>
                    </ul>
            
                <div class="col-md-4">
                  <div class="footer_payment text-center text-lg-right">
                  <a v-if="this.$i18n.locale=='ar'" style="float: left;"
                   href='https://dekakeen.com'>{{$t('Powered By Dekakeen')}}
                  </a>

<a v-else style="float: right;" 
    href='https://dekakeen.com'>{{$t('Powered By Dekakeen')}}</a>

                   </div>
                </div>
                
              
            </div>
           
          
        </div>
       
    </div>
</footer>
<!-- END FOOTER -->
  </template>
  
  <script>
  export default {
   
    name: "Footer",
    props: {
     
    },
    data: function () {
      return {
        logo: "",
        DBitems: null,
        loading: false,
      };
    },
    created() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme5",
        {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
        ))
        .then(() => (this.loading = false));
        
    },
    methods: {
      updatedata() {
        this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme5",
        {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
        ))
        .then(() => (this.loading = false));
        
      }
    },
    watch: {
    langChange() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme5",
        {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
        ))
        .then(() => (this.loading = false));
        
    },
    langChange1() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme5",
        {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
        ))
        .then(() => (this.loading = false));
        
    },
  },

  };
  </script> 
  <style>
  .footer_logo {
    max-width: 200px;
    max-height: 100px;
    object-fit: contain;
}


@media (min-width: 992px)
{
.section_image {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
}

.section_page {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}
}
footer img{
  max-width:200px;
  max-height:100px;
  object-fit: cover;
}

@media screen and (max-width: 600px)

 {
  .footer_column {
    width: 50% !important;
}
}
  </style>
  