<template>
  <div class="card" v-if="!loading">
    <div class="row">
      <div class="trip-image col-4">
          <img :src="DBitems.product.image" />
      </div>
      <div class="card-column col-7">
        <div class="mt-3">
            <h3>{{ DBitems.product.name }}</h3>
          <div style="height: 30px; overflow-y: auto">
            <div v-if="DBitems.final_options.length != 0">
              <p v-for="(item, index) in DBitems.final_options" :key="index">
                {{ item.option }} : {{ item.value }}
              </p>
            </div>
            <div v-if="DBitems.checkbox_options.length != 0">
              <p v-for="(item, index) in DBitems.checkbox_options" :key="index">
                {{ item }}
              </p>
            </div>
          </div>
          <div
            class="
              d-flex
              flex-row
              justify-content-between
              align-items-center
              mt-2
            "
          >
            <div v-if="item.offer_price != 0">
              <h4 v-if=" $i18n.locale == 'ar'">{{ item.offer_price.toFixed(2) }} {{$store.state.unitAR}}</h4>
              <h4 v-else>{{ item.offer_price.toFixed(2) }} {{$store.state.unit}}</h4>
              <!--unitttttt-->
            </div>
            <div v-else>
              <h4 v-if=" $i18n.locale == 'ar'">{{ item.total_price.toFixed(2) }} {{$store.state.unitAR}}</h4>
              <h4 v-else>{{ item.total_price.toFixed(2) }} {{$store.state.unit}}</h4>
              <!--unitttttt-->
            </div>
            <h4 >{{ $t('quantity') }}: {{ item.quantity }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemId: String,
  },
  data: function () {
    return {
      loading: false,
      DBitems: null,
      item: null,
    };
  },
  created() {
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
    
  },
};
</script>

<style scoped>
.card {
  border: 0;
  border-radius: 15px;
  height: 107px;
  box-shadow: 0 2px 10px 0 #e5e5e5;
}

.card .trip-image {
  height: 107px;
}

.card p {
  margin-bottom: 0;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  font-size: 9px;
}

[dir="ltr"] .card .trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

[dir="rtl"] .card .trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card h3 {
  text-align: start;
  font-family: "Araboto-Bold";
  color: #000;
  font-size: 14px;
}

.card h4 {
  text-align: start;
  font-family: "Araboto-Bold";
  color: var(--primary-color);
  font-size: 13px;
}
.card .delete p {
  color: #9c0008;
  font-size: 11px;
  font-family: "Araboto-Bold";
}

[dir="rtl"] .counter {
  background-color: var(--primary-color);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

[dir="ltr"] .counter {
  background-color: var(--primary-color);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.counter h4 {
  text-align: center;
  color: white;
  font-size: 15px;
}

.counter button {
  background-color: transparent;
  border: 0;
  height: 40%;
  width: 100%;
}

@media (max-width: 572px) {
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .card h3 {
    text-align: start;
    font-family: "Araboto-Bold";
    color: #000;
    font-size: 12px;
  }
  .card p {
    font-size: 7px;
  }
  .card h4,
  .card .delete p {
    font-size: 7px;
  }
  .counter h4 {
    font-size: 15px;
}
}
</style>
