<template>
    <!-- START SECTION SHOP -->
<div  class="section_brand">
	<div class="container">
    	<div class="row justify-content-center">
			<div v-if="brands.length !=0" class="col-md-6">
            	<div class="heading_s1 text-center">
                	<h2>{{ $t('brands') }}</h2>

                </div>
                
            </div>
        </div>
        <div v-if="brands.length !=0" class="row" >
           
           <div class="col-12">
                <VueSlickCarousel 
            style="direction:ltr !important"
                                      ref="slick"
                                      v-if="brands.length !=0 "
                                           :options="settings"
                                           @reInit="handlereInit"
                                          
                                      class="client_logo carousel_slider owl-carousel owl-theme nav_style3 brands"
                                      
                                       >

                	<div class="item"  v-for="(item, index) in this.brands">
                    	<brand
                        :image="item.image"
                        :name="item.name"
                        :id="item.id"
                        >
                        </brand>
                    </div>
                   
                   
                </VueSlickCarousel>
                <a class="carousel-control-prev product_next" role="button" data-slide="prev" @click="prev_product()"><i class="ion-chevron-left"></i></a>
                  <a class="carousel-control-next product_prev"  role="button" data-slide="next" @click="next_product()"><i class="ion-chevron-right"></i></a>

               
            </div>
           
    </div>
</div>
<!-- END SECTION SHOP -->



<!-- START SECTION SHOP INFO -->
<div class="section pb_70" >
    	<div class="container">
            <div class="row no-gutters" style="background-color:#f0e0c6 !important;padding: 2%;">
                <div class="col-lg-4">	
                    <div class="icon_box icon_box_style1">
                        <div class="icon">
                            <i class="flaticon-shipped"></i>
                        </div>
                        <div class="icon_box_content">
                            <h5>{{$t('Free Delivery')}}</h5>
                            <p>{{ this.services.free}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">	
                    <div class="icon_box icon_box_style1">
                        <div class="icon">
                            <i class="flaticon-money-back"></i>
                        </div>
                        <div class="icon_box_content">
                            <h5>{{$t('DeliveryMethod')}}</h5>
                            <p>{{ this.services.delivery}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">	
                    <div class="icon_box icon_box_style1">
                        <div class="icon">
                            <i class="flaticon-support"></i>
                        </div>
                        <div class="icon_box_content">
                            <h5>{{$t('PaymentMethod')}}</h5>
                            <p>{{ this.services.payment}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- END SECTION SHOP INFO -->

<!-- START SECTION SUBSCRIBE NEWSLETTER -->
<!-- START SECTION SUBSCRIBE NEWSLETTER -->

</div>
<!-- END MAIN CONTENT -->

</template>
<script>
import brand from "../components/brand_list.vue";
import VueSlickCarousel from 'vue-slick'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: { brand ,VueSlickCarousel},
  data() {
    return {
     DBitems: null,
      loading: false,
      brands:[],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
      type:'new',
      services:null,
      settings:
        {
  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 5,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ],
  // "centerMode": true,
  "centerPadding": "20px",
  "centerMargin": "20px",
   "focusOnSelect": false,
},
   
    };
  },
  created() {

    console.log('branddddddddddddddd');
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/brands",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        if(response.data.data.brands)
        this.brands = response.data.data.brands;

      })
      .then(() => (this.loading = false));
      //services
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/services",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.services = response.data.data;

      })
  },
  watch: {
    langChange() {
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/brands",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        if(response.data.data.brands)
        this.brands = response.data.data.brands;

      })
      .then(() => (this.loading = false));
      //services
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/services",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        if(response.data.data)
        this.services = response.data.data;

      })
    },
  },
  updated() {
    this.langChange=this.$i18n.locale

  },
  methods: {
    next_product() {
            this.$refs.slick.next();
        },

        prev_product() {
            this.$refs.slick.prev();
        },
        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
    

  },
};
</script>

<style scoped>
.section_brand .slick-slider .slick-track, .section_brand .slick-slider .slick-list
{
  width:100px
}

.rtl .brands .slick-track {
    display: flex !important;
    direction: rtl !important;
}

</style>