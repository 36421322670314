<template>
  <div>
    <div class="card" v-if="!loading">
      <div class="row">
        <div class="trip-image col-4">
          <router-link
            :to="{
            name: 'product',
            params: { storeName: $store.storeName, id: this.item.product_id , name:this.DBitems.product.name },
          }"
          >
            <img :src="DBitems.product.image" />
          </router-link>
        </div>
        <div class="card-column col-6">
          <div class="mt-3">
            <router-link
              :to="{
              name: 'product',
              params: { storeName: $store.storeName, id: this.item.product_id , name:this.DBitems.product.name },
            }"
            >
              <h3>{{ DBitems.product.name }}</h3>
            </router-link>
            <div style="height: 30px; overflow-y: auto">
              <div v-if="DBitems.final_options.length != 0">
                <p
                  v-for="(item, index) in DBitems.final_options"
                  :key="index"
                >{{ item.option }} : {{ item.value }}</p>
              </div>
              <div v-if="DBitems.checkbox_options.length != 0">
                <p v-for="(item, index) in DBitems.checkbox_options" :key="index">{{ item }}</p>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center mt-2">
              <div v-if="item.offer_price != 0">
                <h4
                  v-if=" $i18n.locale == 'ar'"
                >{{ item.offer_price.toFixed(2) }} {{$store.state.unitAR}}</h4>
                <h4 v-else>{{ item.offer_price.toFixed(2) }} {{$store.state.unit}}</h4>
                <!--unitttttt-->
              </div>
              <div v-else>
                <h4
                  v-if=" $i18n.locale == 'ar'"
                >{{ item.total_price.toFixed(2) }} {{$store.state.unitAR}}</h4>
                <h4 v-else>{{ item.total_price.toFixed(2) }} {{$store.state.unit}}</h4>
                <!--unitttttt-->
              </div>

              <button
                class="delete"
                style="background: transparent; border: 0"
                @click="show = true"
              >
                <p>
                  <span>
                    <i class="bi bi-trash"></i>
                  </span>
                  {{ $t("delete")}}
                </p>
              </button>
            </div>
          </div>
        </div>
        <div class="col-2 counter">
          <button @click="incrementClick()">
            <h4>+</h4>
          </button>
          <h4>{{ item.quantity }}</h4>
          <button @click="decrementClick()">
            <h4>-</h4>
          </button>
        </div>
      </div>
    </div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdelete')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteItem() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  props: {
    itemId: String
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      item: null,
      show: false
    };
  },
  created() {
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
          /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
    incrementClick() {
      // ++this.counterVal;
      console.log(
        "incrementtt" + this.item.quantity + " " + this.item.quantityAll
      );


      
      //////////////////////////////////////////////////////////
      let S_name_full =
        this.$store.storeName +
        "/" +
        this.item.product_id +
        this.item.final_option +
        this.item.option_check;

      let S_name =
       this.$store.storeName +
        "/" +
        this.item.product_id +
        this.item.final_option;
      console.log("S_name " + S_name);

      let qun = this.item.quantityAll;
      console.log('qun****************',qun)
     let min_qun =this.item.min_quantity;

    
          let  max_qun =this.item.max_quantity;
      console.log("this.item.quantityAll" + this.item.quantityAll);

      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].includes(S_name)) {
          if (AllCookies[i] == S_name_full) {
            console.log("hii i am there ");
          } else {
            qun = qun - this.$cookies.get(AllCookies[i]).quantity;
            min_qun =this.$cookies.get(AllCookies[i]).min_quantity;
            max_qun = this.$cookies.get(AllCookies[i]).max_quantity;
            console.log(
              this.$cookies.get(AllCookies[i]).quantity + " quuuuun " + qun
            );

            console.log(
              this.$cookies.get(AllCookies[i]).min_quantity + " min quuuuun " + min_qun
            );
          }
        }
      }
      //////////////////////////////////////////////////////////
      if (this.item.quantityAll > 0) {
        if (this.item.quantity < qun 
        && this.item.quantity < max_qun 
        // && this.item.quantity > min_qun
        ) {
          ++this.item.quantity;
          this.item.total_price =
            this.item.total_price / (this.item.quantity - 1) +
            this.item.total_price;
          /*if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
          if (this.item.offer_price != 0) {
            this.item.offer_price =
              this.item.offer_price / (this.item.quantity - 1) +
              this.item.offer_price;
          }

          //////////update this key
          let product = {
            product_id: this.item.product_id,
            total_price: this.item.total_price,
            offer_price: this.item.offer_price,
            quantity: this.item.quantity,
            quantityAll: this.item.quantityAll,
            min_quantity: this.item.min_quantity,
            max_quantity: this.item.max_quantity,
            final_option: this.item.final_option,
            option_check: this.item.option_check,
            discount: this.item.discount,
            note: this.item.note
          };
          this.$cookies.set(this.itemId, product);
          this.$parent.updateProducts();
        }
      } else {
        ++this.item.quantity;
        this.item.total_price =
          this.item.total_price / (this.item.quantity - 1) +
          this.item.total_price;
        /* if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
        if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.offer_price / (this.item.quantity - 1) +
            this.item.offer_price;
        }

        //////////update this key
        let product = {
          product_id: this.item.product_id,
          total_price: this.item.total_price,
          offer_price: this.item.offer_price,
          quantity: this.item.quantity,
          quantityAll: this.item.quantityAll,
          final_option: this.item.final_option,
          option_check: this.item.option_check,
          discount: this.item.discount,
          note: this.item.note
        };
        this.$cookies.set(this.itemId, product);
        this.$parent.updateProducts();
      }
    },
    decrementClick() {
      let min_qun =this.item.min_quantity;

    
          let  max_qun =this.item.max_quantity;
      console.log(
        "decrementttt" + this.item.quantity + " " ,this.item
      );
      if (this.item.quantity > 1  
      && this.item.quantity > min_qun
 ) {
        //  --this.counterVal;
        --this.item.quantity;
        //this.price = this.price/2
        this.item.total_price =
          this.item.total_price -
          this.item.total_price / (this.item.quantity + 1);
        /*if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
        if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.offer_price -
            this.item.offer_price / (this.item.quantity + 1);
        }

        //////////update this key
        let product = {
          product_id: this.item.product_id,
          total_price: this.item.total_price,
          offer_price: this.item.offer_price,
          quantity: this.item.quantity,
          quantityAll: this.item.quantityAll,
          min_quantity: this.item.min_quantity,
          max_quantity: this.item.max_quantity,
          final_option: this.item.final_option,
          option_check: this.item.option_check,
          discount: this.item.discount,
          note: this.item.note
        };
        this.$cookies.set(this.itemId, product);
        this.$parent.updateProducts();
      }
    },
    deleteItem() {
      //this.loading = true;
      this.$cookies.remove(this.itemId);
      console.log("remove" + this.$cookies.keys());
      this.$store.state.cartCounter = this.$store.state.cartCounter - 1;
      this.$destroy();
      this.$parent.updateProducts();
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>

<style scoped>
.card {
  border: 0;
  border-radius: 15px;
  height: 107px;
  box-shadow: 0 2px 10px 0 #e5e5e5;
}

.card .trip-image {
  height: 107px;
}

.card p {
  margin-bottom: 0;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  font-size: 9px;
}

[dir="ltr"] .card .trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  transition: 0.5s all;
}

[dir="rtl"] .card .trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: 0.5s all;
}
.card .trip-image img:hover{
  filter: brightness(0.7);
}
.card h3 {
  height: 20px;
    overflow-y: auto;
    
  text-align: start;
  font-family: "Araboto-Bold";
  color: #000;
  font-size: 14px;
}

.card h4 {
  text-align: start;
  font-family: "Araboto-Bold";
  color: var(--primary-color);
  font-size: 13px;
}
.card .delete p {
  color: #9c0008;
  font-size: 11px;
  font-family: "Araboto-Bold";
}

[dir="rtl"] .counter {
  background-color: var(--primary-color);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

[dir="ltr"] .counter {
  background-color: var(--primary-color);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.counter h4 {
  text-align: center;
  color: white;
  font-size: 15px;
}

.counter button {
  background-color: transparent;
  border: 0;
  height: 40%;
  width: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  margin-top: 0;
  color: var(--secondary-color) !important;
  font-family: "Araboto-Medium";
  margin-bottom: 20px;
}

.modal-body {
  margin: 20px 0;
  font-family: "Araboto-Medium";
}

.modal-default-button {
  float: right;
  font-family: "Araboto-Medium";
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
  border-radius: 5px;
}
.modal-default-button:hover {
  background-color: white;
}


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 572px) {
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .card h3 {
    text-align: start;
    font-family: "Araboto-Bold";
    color: #000;
    font-size: 12px;
  }
  .card p {
    font-size: 7px;
  }
  .card h4,
  .card .delete p {
    font-size: 7px;
  }
  .counter h4 {
    font-size: 15px;
  }
}
</style>
