<template>
  <section>
  <!-- START LOGIN SECTION -->
<div class="login_register_wrap section">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-xl-6 col-md-10">
              <div class="login_wrap">
              <div class="padding_eight_all bg-white">
                      <div class="heading_s1">
                          <h3>{{ $t('CreateAccount') }}</h3>
                      </div>
                      <form  v-if=" this.$store.state.auth==0"  @submit.prevent="sendEmail" @keydown="formEmail.onKeydown($event)">
                         
                          <div class="form-group">
                              <input 
                              class="form-control" 
                              type="email"
                              name="email"
                              autocomplete="false"
                              v-model="formEmail.email"
                              placeholder="example@gmail.com"
                               required
                               >
                          </div>
                          
                          
                          <div class="form-group">
                              <button type="submit" :disabled="formphone.busy" class="btn btn-fill-out btn-block" name="register">{{ $t("continue") }}</button>

                          </div>
                      </form>

                      <form v-else @submit.prevent="sendPhone" @keydown="formphone.onKeydown($event)">                           
                         <div class="form-group">
                             <input 
                             class="form-control" 
                             type="text"
                             name="phone"
                             placeholder='xxxx xxxx'
                            autocomplete="false"
                            v-model="formphone.basic_phone"
                            required
                           :minlength=$store.state.country_length
                           :maxlength=$store.state.country_length
                            style="text-align: center;"
                              >
                               
                              <span class="flag">
                                <img :src="this.$store.state.country_flag" >
                                <h2>{{ $store.state.country_code }}
                                  </h2>
                            
                              </span>
                             
                         </div>
                         
                         
                         <div class="form-group">
                             <button type="submit" :disabled="formphone.busy" class="btn btn-fill-out btn-block" name="register">{{ $t("continue") }}</button>

                         </div>
                     </form>

                     <div v-if="validateMessage != ''" style="text-align: center; margin-top: 10px">
            <span style="color: red; margin: 0">{{ validateMessage }}*</span>
          </div>
                      <div class="different_login">
                          <span> {{ $t('or') }}</span>
                      </div>
                     
                      <div class="form-note text-center">{{ $t('Already have an account?') }}
                         <router-link to="login">{{ $t('logIn') }}
                          </router-link>
                          </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- END LOGIN SECTION -->
</section>
  </template>
  <script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";

export default {
components: { VuePhoneNumberInput },
data: function() {
  return {
    isValid: false,
    partphone: null,
    validateMessage: "",
    formphone: new Form({
      phone: null,
      basic_phone: this.$localStorage.get(this.$store.register_phone),
      
    }),
  
    formEmail: new Form({
      email: this.$localStorage.get(this.$store.register_email),
      phone: null,
    })
  };
},
/* mounted() {
  if (this.$i18n.locale == "ar") {
    document
      .getElementsByClassName("input-tel__input")[0]
      .setAttribute("placeholder", "رقم الهاتف");
    document.getElementsByClassName("country-selector__label")[0].innerHTML =
      "رمز الدولة";
    document.getElementsByClassName("input-tel__label")[0].innerHTML =
      "* رقم الهاتف";
  }
},*/

methods: {
  /*async sendPhone() {
    if (this.isValid == true) {
      await this.form
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/genret_code",
          {
            phone: this.form.phone,
            //country: this.form.country,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "-1") {
            console.log(response.data.code);
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.$router.push({
              name: "activateCode",
              params: {
                storeName: this.$store.storeName,
                phone: this.form.phone
              }
            });
            //console.log( response.data.code);
            //console.log( response.data.message);
          }
        });
    } else {
      if (this.$i18n.locale == "en") {
        this.validateMessage = "your number isn't valid";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      } else {
        this.validateMessage = "هذا الرقم غير صالح";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      }
    }
  },*/
  async sendEmail() {
    //if (this.isValid == true) {
      this.$localStorage.set(
                this.$store.register_email,
                this.formEmail.email
              );
    await this.formEmail
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/genret_code/byemail",
        {
          email: this.formEmail.email,
          //country: this.form.country,
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        if (response.data.code == "-1") {
          console.log(response.data.code);
          this.validateMessage = response.data.message;
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else if (response.data.code == "1") {
          this.$router.push({
            name: "activateCode",
            params: {
              storeName: this.$store.storeName,
              email: this.formEmail.email
            }
          });
          //console.log( response.data.code);
          //console.log( response.data.message);
        }
      });
    /*} else {
      if (this.$i18n.locale == "en") {
        this.validateMessage = "your number isn't valid";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      } else {
        this.validateMessage = "هذا الرقم غير صالح";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      }
    }*/
  }


,



  async sendPhone() {
    this.formphone.phone=this.$store.state.country_code+this.formphone.basic_phone
    //if (this.isValid == true) {
      this.$localStorage.set(
                this.$store.register_phone,
                this.formphone.basic_phone
              );

    await this.formphone
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/genret_code",
        {
          phone: this.formphone.phone,
          //country: this.form.country,
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        if (response.data.code == "-1") {
          this.formphone.phone=null;
          console.log(response.data.code);
          this.validateMessage = response.data.message;
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else if (response.data.code == "1") {
          this.$router.push({
            name: "activateCode",
            params: {
              storeName: this.$store.storeName,
              phone: this.formphone.phone,
            }
          });
          //console.log( response.data.code);
          //console.log( response.data.message);
        }
      });
    /*} else {
      if (this.$i18n.locale == "en") {
        this.validateMessage = "your number isn't valid";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      } else {
        this.validateMessage = "هذا الرقم غير صالح";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      }
    }*/
  }



  /*sendPhone1(event) {
    this.isValid = event.isValid;
    if (this.isValid == true) {
      this.form.phone = event.e164; //+ (' +' +event.countryCallingCode));
      //console.log(this.form.phone)
    }
    //console.log(event.isValid);
    //console.log(event);
  }*/
}
};

</script>
<style>
.flag
{
  margin-top: -48px;
    float: left;
    height: 52px;
    border-radius: 40%;
    width: 100px;
    direction: ltr;
}

.flag h2 {
    font-size: 20px !important;
    margin-top: 7px;
    padding: 0 -26px;
    float: right;
}

.flag img {
    width: 45px;
    height: 44px;
    border-radius: 35px;
    border: 1px solid var(--primary-color);
    margin-left: 6px;
    float: left;
    direction: rtl;
}
  </style>