import firebase from 'firebase/app'
import '../node_modules/@firebase/messaging'

let x = null;

try{
var firebaseConfig = {
    apiKey: "AIzaSyAuszUWVAxzQEq7KTLZuZe21_jUC37UxHM",
    authDomain: "etrolly-2e6f3.firebaseapp.com",
    projectId: "etrolly-2e6f3",
    storageBucket: "etrolly-2e6f3.appspot.com",
    messagingSenderId: "741966837303",
    appId: "1:741966837303:web:e781363adea0b2121ab1b2",
    measurementId: "G-920VGXC470"
}


    firebase.initializeApp(firebaseConfig)
   
    if (firebase.messaging.isSupported()) {
    x=firebase.messaging()
    }else {
        console.log('no-support :(')
      }
}
catch(err){ console.log(err)}
export default x;
    