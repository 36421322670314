<template>
  <section id="Second" v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 mb-4 mobile-inactive ">
          <div class="card">
            <router-link :to="(name = 'profile')">
              <i class="bi bi-person-circle"></i>
              {{$t("profile")}}
            </router-link>
            <router-link :to="(name = 'myOrders')">
              <i class="bi bi-bag"></i>
              {{$t("myOrders")}}
            </router-link>
            <router-link :to="(name ='favorites')">
              <i class="bi bi-heart"></i>
              {{ $t("favorites") }}
            </router-link>
            <router-link :to="(name = 'myAddresses')">
              <i class="bi bi-geo-alt"></i>
              {{ $t("myAddresses") }}
            </router-link>
            <router-link :to="(name = 'notifications')">
              <i class="bi bi-bell"></i>
              {{ $t("Notifications") }}
            </router-link>
            <router-link
              :to="{
                    name: 'home1',
                    params: { storeName: this.$store.storeName },
                  }"
              @click.native="logOut()"
            >
              <i class="bi bi-box-arrow-right"></i>
              {{ $t("logOut") }}
            </router-link>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 mb-4">
          <div class="container">
            <div class="card" style="padding:25px 30px 20px 30px">
              <div class="container">
                <div class="d-flex flex-row justify-content-between align-items-center pb-2">
                  <div class="section-title">
                    <h2>{{ $t("Notifications") }}</h2>
                  </div>
                  <button v-if="this.notifications != null" class="delete" @click="show = true">
                    <h6>
                      {{ $t("deleteAll") }}
                      <span style="color:#ed472b">
                        <i class="bi bi-trash"></i>
                      </span>
                    </h6>
                  </button>
                </div>
                <div
                  v-if="loading"
                  class="d-flex flex-row justify-content-center"
                  style="margin-top: 100px; margin-bottom: 200px"
                >
                  <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="35"
                    :dots-num="3"
                    color="var(--primary-color)"
                  />
                </div>
                <div v-else>
                  <div class="row">
                    <div
                      class="collg6 col-sm-6 col-12 mb-4"
                      v-for="(item, index) in this.notifications"
                      :key="index"
                    >
                      <div class="card card1">
                        <a class="content">
                          <div>
                            <div class="d-flex flex-row justify-content-between align-items-center">
                              <h4>{{ item.title }}</h4>
                              <!--<div>
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                              </div>-->
                            </div>
                            <p>{{ item.body }}</p>
                            <div class="d-flex flex-row justify-content-between align-items-center">
                              <span>{{ item.date }}</span>
                              <div
                                style="color:#ed472b; cursor:pointer"
                                @click="deleteNote(item.id,index)"
                              >
                                <i class="bi bi-trash"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count > 1">
                    <b-pagination
                      v-model="page"
                      :total-rows="count"
                      :per-page="pageSize"
                      :prev-text="$t('prev')"
                      :next-text="$t('next')"
                      pills
                      @change="handlePageChange"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdeleteAllNoti')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAll() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show1" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdeleteOneNoti')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteOneNote() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show1 = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </section>
  <!-- End Second -->
</template>


<script>
import card3 from "../components/CardStyle3.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { card3, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      loading: false,
      show: false,
      show1: false,
      deleteNoteId: null,
      deleteNoteIndex: null,
      page: 1,
      count: 0,
      pageSize: 5,
      langChange: "",
      notifications: null
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/new_note",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        if (response.data.data.code == "1") {
          this.notifications = response.data.data.notes;
          this.count = response.data.meta.total;
          console.log(this.notifications);
        }
      })
      .then((this.loading = false));
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/new_note?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            // console.log(this.notifications);
          }
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  updated() {
    this.langChange = this.$i18n.locale;
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/new_note?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            //console.log(this.notifications);
          }
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    deleteAll() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_allnote",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.notifications = null;
            this.count = 0;
          }
        })
        .then(() => {
          this.loading = false;
          this.show = false;
        });
    },
    deleteOneNote() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_note/" +
            this.deleteNoteId,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$delete(this.notifications, this.deleteNoteIndex);
            this.$store.state.notificationUpdate = !this.$store.state
              .notificationUpdate;
            // this.$el.parentNode.removeChild(this.$el);
          }
        })
        .then(() => {
          this.loading = false;
          this.show1 = false;
        });
    },
    deleteNote(itemId, itemIndex) {
      this.show1 = true;
      this.deleteNoteId = itemId;
      this.deleteNoteIndex = itemIndex;
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.section-title h2 {
    font-size: 18px;
    padding-bottom: 0;
    font-family: "Araboto-Black";
    color: var(--primary-color);
}
#Second {
  margin-top: 20px;
  min-height: 500px;
}
.card {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  height: 100%;
}
a {
  display: block;
  padding: 13px 10px 17px 10px;
  width: 100%;
  color: #000;
  font-family: "Araboto-normal";
  font-size: 14px;
  text-align: start;
}
a i {
  padding: 0 10px;
  color: #acacac;
  font-size: 16px;
}
a:hover {
  color: #000 !important;
}
.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #f8f8f8;
  color: #000 !important;
  /*border: 2px solid var(--primary-color);*/
}
/*.modal-body.card1 {
  width: 100%;
  border: 0;
  border-radius: 15px;
  height: 100%;
  box-shadow: 0 2px 20px 0 #ececec;
  padding: 10px;
  color: #000;
}*/

.card1 h4 {
  color: #000;
  text-align: start;
    font-family: "Araboto-Medium";
    font-size: 14px;
}
.card1 p {
  color: var(--secondary-color);
  font-family: "Araboto-normal";
  font-size: 14px;

  margin: 0;
  max-width: 95%;
  overflow-y: auto;
  height: 40px;
  line-break: anywhere;
  text-align: start;
}
.card1 span {
  color: var(--primary-color);
  font-size: 12px;
  text-align: start;
}
.card1 a i:hover {
  color: rgb(237, 71, 43)
}
/*.card1:hover {
  border: 0;
  border-radius: 15px;
  box-shadow: 0 2px 20px 0 #b1b0b0;
  color: var(--primary-color);
}*/
.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  margin-top: 0;
  color: var(--secondary-color) !important;
  font-family: "Araboto-Medium";
  margin-bottom: 20px;
}

.modal-body {
  margin: 20px 0;
  font-family: "Araboto-Medium";
}

.modal-default-button {
  float: right;
  font-family: "Araboto-Medium";
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
  border-radius: 5px;
}
.modal-default-button:hover {
  background-color: white;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.delete {
  background-color: transparent;
  color: #ed472b;
  font-size: 13px;
  border: 0;
}
.delete:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}
/*@media (min-width: 2000px) {
  .collg6 {
    width: 15%;
  }
}*/

@media (max-width: 1200px) {
  .card1 {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .card{
    padding: 25px 5px 20px !important;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
}
</style>
