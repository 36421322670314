<template>
  <section v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
          <!-- START SECTION BREADCRUMB -->
  <div class="breadcrumb_section bg_gray page-title-mini">
      <div class="container"><!-- STRART CONTAINER -->
          <div class="row align-items-center">
            <div class="col-md-6">
                  <div class="page-title">
                  <h1>{{$t('My Account')}}</h1>
                  </div>
              </div>
              <div class="col-md-6">
                  <ol class="breadcrumb justify-content-md-end">
                      <li class="breadcrumb-item"><router-link to="/">{{ $t('home') }}</router-link></li>
                      <li class="breadcrumb-item active">{{$t('My Account')}}</li>
                  </ol>
              </div>
          </div>
      </div><!-- END CONTAINER-->
  </div>
  <!-- END SECTION BREADCRUMB -->
  
  
  <!-- START SECTION SHOP -->
  <div class="section">
    <div class="container">
          <div class="row">
              <div class="col-lg-3 col-md-4">
                  <div class="dashboard_menu">
                      <ul class="nav nav-tabs flex-column" role="tablist">
                
                        <li class="nav-item">
                          <router-link class="nav-link" id="orders-tab" data-toggle="tab" 
                          to="/myOrders" role="tab"
                           aria-controls="orders" aria-selected="false">
                           <i class="ti-shopping-cart-full"></i>{{$t('myOrders')}}</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link 
                          class="nav-link" id="address-tab" data-toggle="tab" 
                          to="/myAddresses" role="tab" 
                          aria-controls="address" aria-selected="true">
                          <i class="ti-location-pin"></i>{{$t('myAddresses')}}</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link active" 
                          id="account-detail-tab active" data-toggle="tab" 
                          to="/profile" role="tab" 
                          aria-controls="account-detail" aria-selected="true">
                          <i class="ti-id-badge"></i>{{$t('Account Details')}}</router-link>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" 
                          style="cursor:pointer"
                           @click="logOut">
                           <i class="ti-lock"></i>{{ $t('logOut') }}</a>
                        </li>
                      </ul>
                  </div>
              </div>
              <div class="col-lg-9 col-md-8">
                  <div class="tab-content dashboard_content">
                   
        
                      <div class="tab-pane fade active show" id="account-detail" role="tabpanel" aria-labelledby="account-detail-tab">
                            <div class="card">
                            <div class="card-header">
                                  <h3>{{$t('Account Details')}}</h3>
                              </div>
                              <div class="card-body">
                           
                                  <form method="post" name="enq"
                                  autocomplete="false"
                                 @submit.prevent="submitInfo"
                                @keydown="form.onKeydown($event)"
                                  >
                                      <div >
                                          <div class="form-group col-md-12">
                                            <label>{{$t('name')}} <span class="required">*</span></label>
                                              <input required="" 
                                             
                      autocomplete="false"
                      class="form-control"
                      type="text"
                      name="name"
                      v-model="form.name"
                      required
                      maxlength="30">
                                           </div>
                                        
                                        <div v-if="this.$store.state.auth==1" class="form-group col-md-12">
                                            <label>{{ $t('Email') }}
                                               <span class="required">*</span></label>
                                          
                                              <input 
                                              autocomplete="false"
                                              class="form-control"
                                              type="email"
                                              name="email"
                                              v-model="form.email"
                                              required
                                              maxlength="50"
                                                >
                                          </div>
                                          <div v-else class="form-group col-md-12">
                                            <label> <span class="required">*</span>{{ $t('Phone') }}</label>
                                            <div class="form-group">
                                              
                                 
                                 <input
                               
                                   class="form-control" 
                                   type="phone"
                                   v-model="phone"
                                   placeholder="xxxx xxxx"
                                   required
                                  :minlength=$store.state.country_length
                                  :maxlength=$store.state.country_length
                                  style="text-align:center"
                                   >
                                   <span class="flag">  
                             <h2>{{ $store.state.country_code }}</h2>
                           <img :src="this.$store.state.country_flag" />
                           
                          
                           </span>
                           
                             </div>
                    
                    <div
                      v-if="errorMessage != ''"
                      style="
                        text-align: center;
                        color: var(--primary-color);
                        padding: 5px;
                      "
                    >
                      <p style="margin: 0">{{ errorMessage }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">

                                          <div class="col-md-6">
                                              <button 
                                              type="submit" 
                                              class="btn btn-fill-out" 
                                              name="submit" 
                                              
                                              :disabled="form.busy"
                                              value="Submit">{{$t('save')}}</button>
                                          </div>
                                          <div class="col-md-6">
                                          <button
                v-if="this.$store.state.auth == 0||this.$store.state.auth == 1"
                @click="resetpass(0)"
                class="btn btn-fill-out reset_password"
                style="color:white !important"
              >
                <p  style="color:white">{{ $t("resetPass") }}</p>
              </button>
            </div>
                                      </div>
                                      <br>
                                    
              </div>
                                  </form>
                         
                              </div>
                             
                   
              
              <div v-if="this.$store.state.auth == 0||this.$store.state.auth == 1">
                
                <vodal
                  :show="codeVodal"
                  animation="slideLeft"
                  @hide="codeVodal = false"
                  :width="500"
                  :height="350"
                  style="margin-top: 20px"
                >
                  <div class="container" style="width: 80%; margin: 10%">
                    <h5
                      style="
                      color: var(--primary-color);
                      margin-left: 20px;
                      margin-right: 20px;
                    "
                    >{{ $t("EntertheCode") }} :</h5>
                    <div dir="ltr" class="mt-5">
                      <CodeInput
                        :loading="false"
                        required
                        class="input"
                        @change="onChange"
                        @complete="onComplete"
                      />
                    </div>
                    <button class="button text-center mt-5 btn btn-fill-out" @click="checkCode()">{{ $t("continue") }}</button>
                   
                    <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
                  </div>
                </vodal>
                <vodal
                  :show="newPassVodal"
                  animation="slideLeft"
                  @hide="newPassVodal = false"
                  :width="500"
                  :height="350"
                  style="margin-top: 20px"
                >
                  <div class="container" style="width: 80%; margin: 10%">
                    <h5
                      style="
                      color: var(--primary-color);
                      margin-left: 20px;
                      margin-right: 20px;
                    "
                    >{{ $t("EnterNewPass") }} :</h5>
                    <div dir="ltr" class="mt-5">
                      <input
                        id="newpassword"
                        class="input-field"
                        type="password"
                        name="name"
                        v-model="Newpassword"
                        :placeholder="$t('EnterPass')"
                        required
                        minlength="8"
                      />
                    </div>
                   
                    <button
                      class="button text-center btn btn-fill-out"
                      @click="SaveChanges()"
                    >{{ $t("SaveChanges") }}</button>
                   
                    <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
                  </div>
                </vodal>
              </div>
                          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END SECTION SHOP -->
<Transition>
  <div v-if="this.show2" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmlogout") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="logOut()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(this.show2 = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
      </section>
      </template>
      <script>
  
  import Form from "vform";
  
  import CodeInput from "vue-verification-code-input";
  
  import Vodal from "vodal";
  
  import "vodal/common.css";
  import "vodal/slide-down.css";
  import "vodal/flip.css";
  import "vodal/slide-left.css";
  
  export default {
    components: {
      Vodal,
      CodeInput,
      
    },
    data: function() {
      return {
        //DBitems: null,
        forgetVodal: false,
        codeVodal: false,
        newPassVodal: false,
  
        errorMessageVodal: "",
        passemail: "",
        Newpassword: null,
        checkcomplete: false,
        finalcode: null,
        phone:null,
        errorMessage: "",
        form: new Form({
          //preview: testImage,
          email: null,
          name: null,
          phone:null,
          show2: false,
        })
      };
    },
    created() {
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/profile",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.form.email = response.data.data.email;
          this.form.name = response.data.data.name;
          this.phone=response.data.data.phone.substr(4);
          console.log('phone ' +this.phone);
          //console.log(this.form)
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/" ;
          }
        });
        if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    },
    methods: {
      logOut() {
      this.loading = true;
      this.axios.get(
        this.$store.state.base_api_url + this.$store.storeName + "/logout",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      );
      //console.group("hiiiiii")
      console.log(
        "logout:" +
          //this.$localStorage.id() +
          this.$localStorage.get(this.$store.storeName)
      );
      //this.$localStorage.remove("userToken");
      this.show2=false;
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        console.log('hhhhhhhhhhhhhhh111');
        if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
          this.$cookies.remove(console.log(AllCookies[i]));
        
        }
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

      }
      console.log(AllCookies);
      /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
      this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
      }*/
      this.$localStorage.remove(this.$store.storeName);
      window.location.reload();
      this.loading = false;
    },
      /*readURL(e) {
        this.preview = URL.createObjectURL(e.target.files[0]);
      },*/
      async submitInfo() {
        this.form.phone=this.$store.state.country_code+this.phone;
  
        const response = await this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/profile/update",
            {
              email: this.form.email,
              name: this.form.name,
              phone:this.form.phone
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.errorMessage = response.data.message;
              setTimeout(() => {
                this.errorMessage = "";
              }, 3000);
            } else if (response.data.code == "-1") {
              this.errorMessage = response.data.message;
              setTimeout(() => {
                this.errorMessage = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessage = response.data.error;
              setTimeout(() => {
                this.errorMessage = "";
              }, 3000);
            }
          })
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href="/";
            }
          });
      },
      resetpass(type) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/reset_password",
            {
              ///update_password
              send_type: type
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.codeVodal = true;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          })
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href="/";
            }
          });
      },
      checkCode() {
        if (this.checkcomplete == true) {
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/verivey_reset_password",
              {
                ///update_password
                code: this.finalcode
              },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                  Authorization:
                    "Bearer " + this.$localStorage.get(this.$store.storeName)
                }
              }
            )
            .then(response => {
              if (response.data.code == "1") {
                this.forgetVodal = false;
                this.codeVodal = false;
                this.newPassVodal = true;
                // console.log(response.data.message);
              } else if (response.data.code == "-1") {
                this.errorMessageVodal = response.data.message;
                setTimeout(() => {
                  this.errorMessageVodal = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessageVodal = response.data.message;
                setTimeout(() => {
                  this.errorMessageVodal = "";
                }, 3000);
              }
            })
            .catch(error => {
              if (error.response.status == "401") {
                this.$localStorage.remove(this.$store.storeName);
                window.location.href =
                  "/";
              }
            });
        } else {
          if (this.$i18n.locale == "en") {
            this.errorMessageVodal = "your code isn't complete";
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          } else {
            this.errorMessageVodal = "الرمز المدخل غير مكتمل";
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        }
      },
      SaveChanges() {
        if (this.Newpassword != null) {
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/auth/update_password",
              {
                ///update_password
                password: this.Newpassword
              },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                  Authorization:
                    "Bearer " + this.$localStorage.get(this.$store.storeName)
                }
              }
            )
            .then(response => {
              if (response.data.code == "1") {
                this.forgetVodal = false;
                this.codeVodal = false;
                this.newPassVodal = false;
                // console.log(response.data.message);
              } else if (response.data.code == "-1") {
                this.errorMessageVodal = response.data.message;
                setTimeout(() => {
                  this.errorMessageVodal = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessageVodal = response.data.message;
                setTimeout(() => {
                  this.errorMessageVodal = "";
                }, 3000);
              }
            })
            .catch(error => {
              if (error.response.status == "401") {
                this.$localStorage.remove(this.$store.storeName);
                window.location.href =
                  "/" ;
              }
            });
        } else {
          this.errorMessageVodal = "error";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      },
      onChange(v) {
        //console.log("onChange ", this.phone);
        this.checkcomplete = false;
      },
      onComplete(v) {
        //console.log("onComplete ", v);
        this.finalcode = v;
        this.checkcomplete = true;
      }
    },
    watch:{
      langchange()
      {
        this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/profile",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.form.email = response.data.data.email;
          this.form.name = response.data.data.name;
          this.phone=response.data.data.phone.substr(4);
          console.log('phone ' +this.phone);
          //console.log(this.form)
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/" ;
          }
        });
      }
    }
  };
  </script>
<style >
.reset_password:hover p
{
color:var(--primary-color) !important;
}
</style>