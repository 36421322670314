<template>
  <!-- ======= Second Section ======= -->
  <section id="Second" class="brands">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("brands") }}</h2>
      </div>
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
      </div>
      <div v-else>
        <div class="row">
          <div
            class="collg5 col-lg-3 col-md-4 col-6 mb-5"
            v-for="(item, index) in this.DBitems"
            :key="index"
          >
            <card6
              :id="item.id"
              :name="item.name"
              :image="item.image"
            ></card6>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center mt-5">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="pageSize"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            pills
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </section>
  <!-- End Second -->
</template>

<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";
import card6 from "../components/CardStyle6.vue";

export default {
  components: { card6, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      page: 1,
      count: 0,
      pageSize: 10,
      langChange:""
      //lang: null,
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get( this.$store.state.base_api_url+this.$store.storeName+"/brands", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => {(this.DBitems = response.data.data.brands)
        this.count = response.data.meta.total;})
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/brands?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.brands;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        }).then(() => {
        this.loading = false;
      });
    },
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/brands?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.brands;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
  },
}
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 20px;
  min-height: 600px;
}
.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) and (min-width: 490px) {
  .col-sm-6 {
    width: 50%;
  }
}
/*@media (min-width: 2300px) {
  .collg5{
    width: 15% !important;
  }
  .row{
    width: 100% !important;
  }
}*/
@media (min-width: 1203px) {
  .col-lg-3 {
    width: 20%;
  }
}
@media (max-width: 490px) {
  h2 {
    font-size: 20px;
  }
  .card {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
}
</style>
