<template>
  <hooper style="height: 100%;padding-top: 0px;padding-bottom: 0px;" :rtl=" $i18n.locale == 'ar' ? true : false" :settings="hooperSettings">
    <slide style="padding:10px" v-for="(item,index) in items" :key="index">
      <card6
                :id="item.id"
                :name="item.name"
                :image="item.image"
              ></card6>
    </slide>
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
    <!--<hooper-pagination slot="hooper-addons"></hooper-pagination>
    <hooper-progress slot="hooper-addons"></hooper-progress>-->
  </hooper>
</template>

<script>
import card6 from "./CardStyle6.vue";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'App',
  props:{items : Array},
  components: {
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
    card6
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 2,
        initialSlide:0,
        itemsToSlide:2,
        /*infiniteScroll:true,*/
        autoPlay:true,
        playSpeed:3000,
        trimWhiteSpace:true,
        //playSpeed:2000,
        //transition:300,
        wheelControl:false,

        breakpoints: {
        
          475: {
            itemsToShow: 2,
            itemsToSlide:1
          },
          768: {
            itemsToShow: 3
          },
          992: {
            itemsToShow: 4
          },
          1200: {
            itemsToShow: 5,
          }
        }
      }
    };
  }
};
</script>
<style scoped>
#About {
  padding-top: 250px;
  min-height: 600px;
}

h1, h4 {
  cursor: default;
}

a {
  text-decoration: none
}

h1 {
  margin: 0;
  font-family: 'Dosis', 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 4em;
  color: #2c3e50;
}

h4, a {
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #7f8c8d;
}

img.google {
  position: absolute;
  bottom: 0;
  left: 5px;
}

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 240px;
  background: linear-gradient(to bottom, transparent 0%, rgba(255,255,255,1) 40%);
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.location {
  font-family: 'Dosis', 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  color: #5dc596;
}

.social {
  justify-content: flex-end;
  flex-direction: row;
}

.social iframe {
  margin: 0 4px;
}

.button {
  display: inline-block;
  width: 180px;
  padding: 12px 14px;
  background-color: #4fc08d;
  font-weight: 700;
  color: #fff;
  border-bottom: 2px solid #3aa373;
  border-radius: 4px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.button:hover {
  background-color: #5dc596;
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
}


</style>
