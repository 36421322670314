<template>
  <div>
    <div v-if="this.$store.state.langchange1">{{ updatedata() }}</div>
    <footer id="footer" v-if="!loading">
      <div style="background-color:#f8f9fb;padding: 10px ">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h2 
                class="mt-5 mb-5" style="line-height:50px"
              >اجعل التسوق عبر الإنترنت أسهل من خلال تطبيق الهاتف المحمول الخاص بنا</h2>
              <p
                class="mt-4 mb-4"
              >نحن نقدم أفلامًا عالية الجودة وأفضل اختيار وثائقي ، والقدرة على التصفح أبجديًا وحسب النوع</p>
              <div class="social-linkes1 mt-5">
                <a
                  v-if="DBitems.social.andrioad_link!=null"
                  target="_blank"
                  :href="DBitems.social.andrioad_link"
                >
                  <img class="m-1" src="../../../public/assets/image/icons/Google play.png" />
                </a>
                <a
                  v-if="DBitems.social.ios_link!=null"
                  target="_blank"
                  :href="DBitems.social.ios_link"
                >
                  <img class="m-1" src="../../../public/assets/image/icons/App Store.png" />
                </a>
              </div>
            </div>
            <div class="col-md-6 mobile-inactive">
              <img style="max-height:340px;margin:20px 20%" src="https://static.vecteezy.com/system/resources/previews/021/179/548/non_2x/online-shopping-icon-hand-icon-showing-mobile-phone-with-shopping-cart-logo-free-png.png" />
            </div>
          </div>
        </div>
      </div>
      <!--<div class="footer-image mobile-inactive"></div>-->
      <div class="container mt-5">
        <div class="row footer-width" style="width: 100%">
          <div class="col-lg-4 mb-3">
            <div class="logo d-flex flex-row">
              <a href>
                <img
                  :src="this.$store.state.logo_footer"
                  alt
                  class="img-fluid"
                  style="max-width:130px"
                />
              </a>
            </div>
            <h2 class="mb-4">{{ DBitems.market.name }}</h2>
            <p>{{ DBitems.market.description }}</p>
            <p style="color: var(--primary-color)">{{ DBitems.market.sub_description }}</p>
          </div>
          <div class="container mobile-active">
            <hr />
          </div>
          <div class="col-lg-4 col-6 mb-3" style="margin-top: 75px">
            <div class="d-flex flex-row justify-content-around">
              <div>
                <h2 class="mb-4">{{ $t("quickAccess") }}</h2>
                <router-link
                  :to="{
                    name: 'about',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                  <p>{{ $t("about") }}</p>
                </router-link>
                <router-link
                  :to="{
                    name: 'privacy',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                  <p>{{ $t("privacyPolicy") }}</p>
                </router-link>
                <router-link
                  :to="{
                    name: 'contactUs',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                  <p>{{ $t("contactUs") }}</p>
                </router-link>
                <router-link
                  :to="{
                name: 'shoppingCart',
                params: { storeName: this.$store.storeName },
              }"
                >
                  <p>{{$t('shoppingCart')}}</p>
                </router-link>
              </div>
            </div>
          </div>
          <!--animate__animated animate__fadeInLeft-->
          <div class="col-lg-4 col-6 mb-3" style="margin-top: 75px">
            <div class="d-flex flex-row justify-content-around">
              <div class="contact-info">
                <h2 class="mb-4">{{ $t("contact") }}</h2>
                <a
                  target="_blank"
                  :href="'tel:'+DBitems.social.phone"
                >
                  <p class="phone">{{ DBitems.social.phone }}</p>
                  <!--edittttttttttttt tow phone-->
                </a>
                <a target="_blank" :href="'mailto:'+  DBitems.social.email">
                  <!--edittttttttttttt-->
                  <p>{{ DBitems.social.email }}</p>
                </a>
                <a>
                  <p>{{ DBitems.social.website }}</p>
                </a>
              </div>
            </div>
            <div class="social-linkes mt-4">
              <a target="_blank" :href="DBitems.social.instgram">
                <img class="m-1" src="../../../public/assets/image/icons/insta.png" />
              </a>
              <a target="_blank" :href="DBitems.social.twiter">
                <img class="m-1" src="../../../public/assets/image/icons/twitter.svg" />
              </a>
              <a target="_blank" :href="DBitems.social.facebook">
                <img class="m-1" src="../../../public/assets/image/icons/facebook.svg" />
              </a>
              <a target="_blank" :href="DBitems.social.tiktok">
                <img class="m-1" src="../../../public/assets/image/icons/tiktok.png" />
              </a>
              <a
                target="_blank"
                :href="'https://api.whatsapp.com/send?phone='+DBitems.social.whatsapp+'&text=Hello!'"
              >
                <img class="m-1" src="../../../public/assets/image/icons/whats.png" />
              </a>
              <a target="_blank" :href="DBitems.social.snapchat">
                <img class="m-1" src="../../../public/assets/image/icons/snap.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
//import { KinesisContainer, KinesisElement} from 'vue-kinesis'
export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Footer",
  //components:{ KinesisContainer, KinesisElement },
  props: {
    // msg: String,
  },
  data: function() {
    return {
      //storeName: "markat",
      logo: "",
      DBitems: null,
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => (this.DBitems = response.data.data))
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/footer",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => (this.DBitems = response.data.data))
        .then(() => (this.loading = false));
      this.$store.state.langchange1 = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#footer {
  background-color: #fff;
}
#footer .social-linkes1 img {
    width: 150px;
    cursor: pointer;
}
</style>
